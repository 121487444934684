import { FC } from 'react'

import { ProfileCard } from 'entities/profileCard'

import { IAchievement } from 'shared/api/account'
import achieve from 'shared/assets/achieve.webp'

import './userAchievements.scss'

interface IUserAchievements {
	readonly achievements: IAchievement[]
}

export const UserAchievements: FC<IUserAchievements> = ({ achievements }) => {
	const publishedAchievements = achievements.filter(
		(achievement) => achievement.is_published
	)
	return (
		<article>
			<ProfileCard>
				<h3 className="user-achievements__title">Достижения</h3>
				{publishedAchievements.length === 0 ? (
					<p className="bold">В разработке</p>
				) : (
					<ul className="user-achievements__list">
						{publishedAchievements.map((achievement) => (
							<li
								key={achievement.id}
								className="user-achievements__list__item"
							>
								<div className="user-achievements__list__item__icon">
									<img
										className="image-cover"
										src={achieve}
										alt={'Достижение'}
									/>
								</div>
								<span className="user-achievements__list__item__description">
									{achievement.title}
								</span>
							</li>
						))}
					</ul>
				)}
			</ProfileCard>
		</article>
	)
}
