import clsx from 'clsx';

import { FC } from 'react';
import './metro.scss';
import { MetroIcon } from 'shared/icons';

interface IClassGym {
  title: string;
  className?: string;
}

export const Metro: FC<IClassGym> = ({ title, className }) => {
  return (
    <div className={clsx('metro', className)}>
      <span className="metro__icon">
        <MetroIcon white />
      </span>
      <span>{title}</span>
    </div>
  );
};
