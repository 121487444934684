import clsx from 'clsx';
import { FC } from 'react';

import './dots.scss';
import { useSliderStore } from 'entities/slider/model/slider-store';

interface IDot {
  readonly slideNumber: number;
}

export const Dot: FC<IDot> = ({ slideNumber }) => {
  const { goToSlide, slide } = useSliderStore(({ goToSlide, slide }) => ({ goToSlide, slide }));
  return (
    <button
      aria-label={`Slide ${slideNumber + 1}`}
      className={clsx('slider-dots__dot', slide === slideNumber && 'selected')}
      onClick={() => goToSlide(slideNumber)}
    ></button>
  );
};
