import { Link } from 'react-router-dom'

import { BgBalls } from 'shared/ui/bgBalls'
import { Logo } from 'shared/ui/logo'

import './Footer.scss'

function Footer() {
	return (
		<footer className="footer">
			{/* <BgBalls /> */}
			<div className="footer__wrapper">
				<div className="footer__wrapper__logo">
					<Logo className="footer__wrapper__logo__image" />
				</div>
				<Link to="/page/policy">Политика конфиденциальности</Link>
			</div>
		</footer>
	)
}

export default Footer
