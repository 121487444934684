import clsx from 'clsx';
import { ProfileCard } from 'entities/profileCard';
import { Progress } from 'entities/progress';
import { FC, useState } from 'react';
import { BigFreezeIcon, GroupIcon, SubscribeIcon } from 'shared/icons';
import { formatDate } from 'shared/lib/utils';
import { ISubscribe } from 'shared/types';
import { IconTitle } from 'shared/ui/iconTitle';
import './profileSubscribe.scss';
import { FreezeIcon } from 'shared/icons/freezeIcon';
import { Button } from 'shared/ui/button';
import { useMutation } from '@tanstack/react-query';
import { freezeSubscribe, unFreezeSubscribe } from 'shared/api/product';

interface IProfileSubscribe {
  readonly isDefault: boolean;
  readonly subscribe: ISubscribe;
}

export const ProfileSubscribe: FC<IProfileSubscribe> = ({ isDefault, subscribe }) => {
  const { balance, freeze, product, date_end, is_activated, id } = subscribe;
  const [isFreezeSubscribe, setIsFreezeSubscribe] = useState(freeze);
  const { mutate: freezeMutate } = useMutation({
    mutationKey: ['freezeSubscribe'],
    mutationFn: (id: number) => freezeSubscribe(id),
    onSuccess: () => setIsFreezeSubscribe(true),
  });
  const { mutate: unFreezeMutate } = useMutation({
    mutationKey: ['unFreezeSubscribe'],
    mutationFn: (id: number) => unFreezeSubscribe(id),
    onSuccess: () => setIsFreezeSubscribe(false),
  });
  return (
    <article className="profile-subscribe">
      <ProfileCard className={clsx('profile-subscribe__wrapper', isDefault && !isFreezeSubscribe && 'active')}>
        <div className="profile-subscribe__header">
          <p className="green-highlight uppercase bold">{product}</p>
          <IconTitle Icon={GroupIcon} title="Нет данных" className="fz-12" />
        </div>
        <SubscribeIcon className={clsx('profile-subscribe__icon', isDefault && 'active')} />
        <div className="profile-subscribe__info">
          <p>Куплен</p>
          <p>Нет данных</p>
        </div>
        <div className="profile-subscribe__info">
          <p>Истекает</p>
          <p className={clsx(is_activated && 'green-highlight')}>{date_end ? formatDate(date_end) : '∞'}</p>
        </div>
        <Progress title="Осталось тренировок" maxValue={5} value={balance} />
        {isFreezeSubscribe && (
          <div className="profile-subscribe__freeze">
            <BigFreezeIcon />{' '}
          </div>
        )}
        <div className="profile-subscribe__actions">
          {!isFreezeSubscribe ? (
            <>
              <Button rounded theme="black">
                Выбрать основным
              </Button>
              <Button onClick={() => freezeMutate(id)} rounded theme="black">
                Заморозить
              </Button>
            </>
          ) : (
            <Button onClick={() => unFreezeMutate(id)} rounded theme="black">
              Разморозить
            </Button>
          )}
        </div>
      </ProfileCard>
      {isDefault && !isFreezeSubscribe && (
        <span className="profile-subscribe__tip">Используется для записи на тренировки по умолчанию</span>
      )}
      {isFreezeSubscribe && <span className="profile-subscribe__tip">Абонемент заморожен</span>}
    </article>
  );
};
