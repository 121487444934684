import { FC } from 'react'
import { useOutletContext } from 'react-router-dom'

import { useUserProfileQuery } from 'pages/profile/api/userProfile'

import { GameData } from 'widgets/profileAbout/gameData'
import { UserAchievements } from 'widgets/profileAbout/userAchievments'
import { UserDescription } from 'widgets/profileAbout/userDescription'
import { UserInfo } from 'widgets/profileAbout/userInfo'

import { IUserData } from 'shared/api/account'

import './profileAbout.scss'

interface IProfileAbout {}

export const ProfileAbout: FC<IProfileAbout> = () => {
	const { userProfile } = useOutletContext() as { userProfile: IUserData }
	return (
		<div className="profile-about">
			<h1 className="profile-about__title">Мой профиль</h1>
			<section className="profile-about__content">
				<UserInfo data={userProfile} />
				<UserDescription data={userProfile} />
				<GameData data={userProfile} />
			</section>
			<UserAchievements achievements={userProfile.achievements} />
		</div>
	)
}
