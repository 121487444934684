import { ISubscribe } from 'shared/types'

import { isSubscribeAvailableForClass } from './isSubscribeAvailableForClass'

export const countAvailableSubscribes = (
	subscribes: ISubscribe[] | null,
	date: string
) => {
	return subscribes?.filter(
		(sub) => !sub.freeze && isSubscribeAvailableForClass(date, sub.date_end)
	).length
}
