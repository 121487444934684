import { FC } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { useUserProfileQuery } from 'pages/profile/api/userProfile'

import { ProfileSubscribe } from 'widgets/profileSubscribe'

import { IUserData } from 'shared/api/account'
import { Button } from 'shared/ui/button'

import './profileSubscribes.scss'

interface IProfileSubscribes {}

export const ProfileSubscribes: FC<IProfileSubscribes> = () => {
	const { userProfile } = useOutletContext() as { userProfile: IUserData }
	const navigate = useNavigate()
	return (
		<div className="profile-subscribes">
			<div className="profile-subscribes__header">
				<h1 className="uppercase">Абонементы</h1>
				<Button
					className="profile-subscribes__header__button"
					onClick={() => navigate('/payment')}
				>
					Купить абонемент
				</Button>
			</div>
			<section className="profile-subscribes__content">
				{userProfile.balance.map((subscribe, idx) => (
					<ProfileSubscribe
						isDefault={idx === 0}
						key={subscribe.id}
						subscribe={subscribe}
					/>
				))}
			</section>
		</div>
	)
}
