import { FC } from 'react';

interface ILeftArrowBlack {
  readonly className?: string;
}

export const ArrowBlackBg: FC<ILeftArrowBlack> = ({ className }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="none" />
      <rect width="50" height="50" rx="25" fill="url(#paint0_linear_3491_1021)" />
      <path
        d="M27.75 16.75L19.9813 24.5188C19.8553 24.6473 19.7847 24.82 19.7847 25C19.7847 25.18 19.8553 25.3527 19.9813 25.4813L27.75 33.25"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3491_1021"
          x1="50"
          y1="1.53567e-06"
          x2="19.1509"
          y2="59.8682"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.047117" stopColor="#222222" />
          <stop offset="0.910363" stopColor="#111111" />
          <stop offset="1" stopColor="#292A2D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
