import { create } from 'zustand';

interface ISliderStore {
  slide: number;
  setSlide: (slide: number) => void;
  items: string[];
  setItems: (items: string[]) => void;
  touchPosition: number | null;
  setTouchPosition: (touchPosition: number | null) => void;
  changeSlide: (direction: number) => void;
  goToSlide: (slideNumber: number) => void;
}

export const useSliderStore = create<ISliderStore>()((set, get) => ({
  slide: 0,
  setSlide: (slide) => set({ slide }),
  items: [],
  setItems: (items) => set({ items }),
  touchPosition: null,
  setTouchPosition: (touchPosition) => set({ touchPosition }),
  changeSlide: (direction = 1) => {
    const slide = get().slide;
    const items = get().items;
    const setSlide = get().setSlide;
    let slideNumber = 0;
    if (slide + direction < 0) {
      slideNumber = items.length - 1;
    } else {
      slideNumber = (slide + direction) % items.length;
    }
    setSlide(slideNumber);
  },
  goToSlide: (slideNumber) => set({ slide: slideNumber % get().items.length }),
}));
