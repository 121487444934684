import { FC } from 'react';

import img from 'shared/assets/metro-icon.webp';
import imgWhite from 'shared/assets/metro-white.webp';

interface IMetroIcon {
  className?: string;
  white?: boolean;
}

export const MetroIcon: FC<IMetroIcon> = ({ className, white }) => {
  return <img className={className} src={white ? imgWhite : img} alt="Иконка метро" />;
};
