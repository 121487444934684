import clsx from 'clsx';
import { FC } from 'react';
import { LocationIcon } from 'shared/icons';
import './gymAddress.scss';

interface IClassAddress {
  title: string;
  className?: string;
}

export const GymAddress: FC<IClassAddress> = ({ title, className }) => {
  return (
    <div className={clsx('class-address', className)}>
      <LocationIcon />
      <span>{title}</span>
    </div>
  );
};
