import clsx from 'clsx'
import { FC } from 'react'

import { ALL_LEVELS, LIGHT_LEVEL } from 'shared/constants/levels'

import './levelInfo.scss'

interface ILevelInfo {
	readonly levelTitle: string
	readonly levelColor: string
	readonly width?: number
	readonly is_changed_level: boolean
	readonly className?: string
}

export const LevelInfo: FC<ILevelInfo> = ({
	levelTitle,
	levelColor,
	width = 3,
	is_changed_level,
	className,
}) => {
	const currentLevel =
		ALL_LEVELS.find((lvl) => lvl.title === levelTitle) ?? LIGHT_LEVEL
	return (
		<div className={clsx('level-info', className)}>
			<div className="level-info__bar">
				{currentLevel.getColors(levelColor).map((color, idx) => (
					<div
						key={idx}
						style={{
							width: `${width}px`,
							height: `${width * (idx + 1 * 3)}px`,
							backgroundColor: color,
						}}
					/>
				))}
			</div>
			<span className={clsx(is_changed_level && 'red-highlight')}>
				{levelTitle}
			</span>
		</div>
	)
}
