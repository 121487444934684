import { useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Files
import 'swiper/css';
import 'swiper/css/pagination';
import { api } from '../../../constants/api';
import './Birthday.scss';
import { birthdaySliderOptions, convertBirthdayDate } from './index';

// Context
import { clearSession } from 'shared/lib/auth/sessions';
import { useSessionStore } from 'shared/model';

const Birthday = () => {
  const setSession = useSessionStore((state) => state.setSession);

  // State
  const [birthdayObj, setBirthday] = useState({}); // user's birthday object

  useEffect(() => {
    // Получение данных дней рождений
    api
      .get('birthday/')
      .then(({ data }) => setBirthday(data))
      .catch((error) => {
        // Logout user
        if (error.response.status === 401) {
          clearSession();
          setSession(null);
        }
      });
  }, []);

  return (
    <div className="main-content__item">
      <div className="birthday-slider">
        <h2 className="page-title">Дни рождения</h2>

        <Swiper {...birthdaySliderOptions} modules={[Pagination, Navigation]}>
          {Object.entries(birthdayObj).map(([birthDayTitle, birthDayPeople], index) => {
            return (
              <SwiperSlide className="birthday-slider__item" key={index}>
                <div className="birthday-wrapper">
                  <div className="birthday">
                    {/*** Birthday date ***/}
                    <p className="birthday__title">{convertBirthdayDate(birthDayTitle)}</p>

                    {/*** Birthday user list ***/}
                    <ul className="birthday__list">
                      {birthDayPeople.map(({ id, first_name, last_name }) => {
                        return (
                          <li className="birthday__list-item" key={id}>
                            <p className="birthday__list-username">
                              {first_name} {last_name}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/*** Birthday decor triangle ***/}
                  <div className="birthday__decor" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Birthday;
