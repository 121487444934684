import { FC } from 'react'

import { IUserData } from 'shared/api/account'
import { MailIcon, PhoneIcon, TelegramCircleIcon } from 'shared/icons'
import { formatPhone } from 'shared/lib/utils'

import def from './image.png'
import './userInfo.scss'

interface IUserInfo {
	readonly data: IUserData
}

export const UserInfo: FC<IUserInfo> = ({ data }) => {
	const contacts = [
		{
			data: data.phone ? formatPhone(data.phone) : '-',
			icon: <PhoneIcon />,
		},
		{
			data: data.telegram ?? '-',
			icon: <TelegramCircleIcon />,
		},
		{
			data: data.email ?? '-',
			icon: <MailIcon />,
		},
	]
	return (
		<article className="user-info">
			<div className="user-info__main">
				<img
					className="user-info__main__avatar"
					src={data.avatar ?? def}
					alt="Аватар игрока"
				/>
				<p className="user-info__main__name">
					{data.first_name} {data.last_name}
				</p>
			</div>
			<ul className="user-info__contacts">
				{contacts.map(({ data, icon }, idx) => (
					<li key={idx} className="user-info__contacts__item">
						{icon}
						<span>{data}</span>
					</li>
				))}
			</ul>
		</article>
	)
}
