import { FC } from 'react';
import { ChangePasswordForm } from 'widgets/changePasswordForm';
import './changePassword.scss';

interface IChangePassword {}

export const ChangePassword: FC<IChangePassword> = () => {
  return (
    <div className="change-password">
      <h1 className="change-password__title uppercase">Изменение пароля</h1>
      <ChangePasswordForm />
    </div>
  );
};
