import { FC } from 'react';

interface ILocationIconBlack {
  readonly className?: string;
}

export const LocationIconBlack: FC<ILocationIconBlack> = ({ className }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="black" />
      <g clipPath="url(#clip0_3450_3424)">
        <path
          d="M24.7601 37.5C24.6334 37.5007 24.5078 37.4764 24.3905 37.4285C24.2732 37.3806 24.1665 37.31 24.0765 37.2208L17.2693 30.4809C16.2865 29.5046 15.5065 28.3435 14.9742 27.0645C14.442 25.7855 14.168 24.4139 14.168 23.0286C14.168 21.6432 14.442 20.2716 14.9742 18.9926C15.5065 17.7136 16.2865 16.5525 17.2693 15.5762C19.2643 13.6053 21.9557 12.5 24.7601 12.5C27.5646 12.5 30.256 13.6053 32.251 15.5762C33.2338 16.5525 34.0138 17.7136 34.546 18.9926C35.0783 20.2716 35.3523 21.6432 35.3523 23.0286C35.3523 24.4139 35.0783 25.7855 34.546 27.0645C34.0138 28.3435 33.2338 29.5046 32.251 30.4809L25.4438 37.2208C25.3538 37.31 25.2471 37.3806 25.1298 37.4285C25.0125 37.4764 24.8869 37.5007 24.7601 37.5ZM24.7601 14.3919C22.4621 14.3863 20.2559 15.2937 18.6269 16.9145C17.8246 17.7119 17.1878 18.66 16.7533 19.7043C16.3189 20.7486 16.0952 21.8686 16.0952 22.9997C16.0952 24.1308 16.3189 25.2507 16.7533 26.2951C17.1878 27.3394 17.8246 28.2875 18.6269 29.0848L24.7601 35.1795L30.8934 29.0848C31.6957 28.2875 32.3324 27.3394 32.7669 26.2951C33.2014 25.2507 33.4251 24.1308 33.4251 22.9997C33.4251 21.8686 33.2014 20.7486 32.7669 19.7043C32.3324 18.66 31.6957 17.7119 30.8934 16.9145C29.2644 15.2937 27.0582 14.3863 24.7601 14.3919Z"
          fill="white"
        />
        <path
          d="M24.7595 27.8715C23.8073 27.8715 22.8766 27.5892 22.0849 27.0602C21.2932 26.5312 20.6761 25.7793 20.3118 24.8997C19.9474 24.02 19.8521 23.052 20.0378 22.1181C20.2236 21.1843 20.6821 20.3265 21.3554 19.6532C22.0286 18.9799 22.8864 18.5214 23.8203 18.3357C24.7542 18.1499 25.7221 18.2452 26.6018 18.6096C27.4815 18.974 28.2334 19.591 28.7623 20.3827C29.2913 21.1744 29.5737 22.1052 29.5737 23.0573C29.5737 24.3341 29.0665 25.5587 28.1636 26.4615C27.2608 27.3643 26.0363 27.8715 24.7595 27.8715ZM24.7595 20.1688C24.1882 20.1688 23.6297 20.3382 23.1547 20.6556C22.6797 20.973 22.3095 21.4242 22.0909 21.952C21.8722 22.4798 21.815 23.0606 21.9265 23.6209C22.0379 24.1812 22.313 24.6959 22.717 25.0998C23.121 25.5038 23.6357 25.7789 24.196 25.8904C24.7563 26.0018 25.3371 25.9446 25.8649 25.726C26.3927 25.5074 26.8438 25.1371 27.1612 24.6621C27.4786 24.1871 27.648 23.6286 27.648 23.0573C27.648 22.2913 27.3437 21.5566 26.802 21.0149C26.2603 20.4732 25.5256 20.1688 24.7595 20.1688Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3450_3424">
          <rect width="25" height="25" fill="white" transform="translate(12.5 12.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
