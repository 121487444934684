import clsx from 'clsx';
import { FC } from 'react';
import { SuccessIcon } from 'shared/icons';
import { FreezeIcon } from 'shared/icons/freezeIcon';
import './modalCheckbox.scss';

interface IModalCheckbox {
  readonly className?: string;
  readonly active: boolean;
  readonly freeze?: boolean;
  readonly children: React.ReactNode;
  readonly disabled?: boolean;
  readonly onClick?: () => void;
}

export const ModalCheckbox: FC<IModalCheckbox> = ({ active, className, children, freeze, disabled, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx('modal-checkbox', className, active && 'active', (disabled || freeze) && 'disabled')}
    >
      {children}
      <SuccessIcon className={clsx('modal-checkbox__icon')} />
      {freeze && <FreezeIcon />}
    </div>
  );
};
