import { FC } from 'react';

interface IFavoriteIconBlack {
  readonly className?: string;
}

export const FavoriteIconBlack: FC<IFavoriteIconBlack> = ({ className }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="black" />
      <path
        d="M36.093 14.2021C34.7704 13.1079 33.0718 12.5066 31.3112 12.5066C29.2776 12.5066 27.3091 13.3152 25.9112 14.7275C25.66 14.9818 25.4244 15.2548 25.2032 15.5512C23.4914 13.273 20.6311 12.1041 17.8022 12.6211C15.4547 13.0471 13.6091 14.3371 12.3203 16.4526C10.5018 19.4394 10.353 22.3202 11.8804 25.0153C12.6969 26.4572 13.7561 27.8623 15.1191 29.3109C17.6154 31.9645 20.5703 34.4831 24.4187 37.2343C24.6646 37.4108 24.9279 37.5 25.2026 37.5C25.6256 37.5 25.9112 37.2861 26.0528 37.1813C29.5253 34.7018 32.253 32.4164 34.6378 29.9851C35.9694 28.6282 37.4806 26.9603 38.5857 24.8774C39.0593 23.9874 39.5991 22.7896 39.5672 21.4212C39.4961 18.4766 38.3278 16.0477 36.093 14.2021ZM36.7768 23.9169C35.7929 25.7691 34.4046 27.2978 33.1755 28.5498C30.9702 30.7985 28.4485 32.9267 25.199 35.273C21.6627 32.7146 18.9254 30.3653 16.6116 27.9045C15.3625 26.5771 14.3979 25.3015 13.6622 24.0031C12.4974 21.9484 12.627 19.8865 14.0707 17.5173C15.042 15.9218 16.4206 14.9517 18.1692 14.6342C18.5042 14.5727 18.8434 14.5426 19.1778 14.5426C21.2506 14.5426 23.0865 15.6874 24.1235 17.6752L24.2994 18.0072C24.4802 18.3464 24.8526 18.5392 25.2201 18.5483C25.6051 18.541 25.9528 18.3211 26.1221 17.9752C26.486 17.2329 26.8831 16.6569 27.3676 16.1664C28.3834 15.1415 29.8205 14.5528 31.3112 14.5528C32.5964 14.5528 33.831 14.9884 34.7885 15.7802C36.5696 17.251 37.4625 19.1128 37.5198 21.4676C37.5397 22.3563 37.1558 23.2035 36.7768 23.9169Z"
        fill="white"
      />
    </svg>
  );
};
