import clsx from 'clsx';
import { FC } from 'react';
import './gymPhoto.scss';
import { defaultGym } from 'shared/assets';

interface IGymPhoto {
  img: string | null;
  className?: string;
}

export const GymPhoto: FC<IGymPhoto> = ({ img, className }) => {
  const url = img ?? defaultGym;
  return <img alt="Фото зала" src={url} className={clsx('gym-photo', className)} />;
};
