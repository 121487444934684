import clsx from 'clsx';
import { ButtonHTMLAttributes, FC } from 'react';
import './button.scss';
import { FadeLoader, SyncLoader } from 'react-spinners';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly theme?: 'green' | 'transparent-green' | 'red' | 'yellow' | 'black';
  readonly isLoading?: boolean;
  readonly bold?: boolean;
  readonly rounded?: boolean;
}

export const Button: FC<IButton> = (props) => {
  const {
    children,
    className,
    theme = 'transparent-green',
    type = 'button',
    isLoading,
    bold = false,
    rounded = false,
    ...rest
  } = props;

  return (
    <button
      type={type}
      {...rest}
      className={clsx(
        'button',
        `button_theme_${theme}`,
        className,
        isLoading && 'button__loading',
        bold && 'button__bold',
        rounded && 'button__rounded'
      )}
    >
      {isLoading ? <span className="button__loader"></span> : children}
    </button>
  );
};
