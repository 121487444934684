import clsx from 'clsx';
import { Arrows, Dots, Slide, useSliderStore } from 'entities/slider';
import { CSSProperties, FC, TouchEvent, useEffect } from 'react';
import DefImg from 'shared/assets/default.jpg';
import './slider.scss';

interface ISlider {
  readonly className?: string;
  readonly autoPlay: boolean;
  readonly gym: string;
  readonly autoPlayTime?: number;
}

export const Slider: FC<ISlider> = ({ className, autoPlay = false, autoPlayTime = 5000, gym }) => {
  const { setItems, touchPosition, setTouchPosition, changeSlide, items, slide } = useSliderStore();
  useEffect(() => {
    const loadImages = async () => {
      const images = [DefImg, DefImg, DefImg] as string[];
      setItems(images);
    };
    loadImages();
  }, [setItems]);

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (touchPosition === null) {
      return;
    }
    const currentPosition = e.touches[0].clientX;
    const diff = touchPosition - currentPosition;
    if (diff > 10) {
      changeSlide(1);
    } else if (diff < -10) {
      changeSlide(-1);
    }
    setTouchPosition(null);
  };

  useEffect(() => {
    if (!autoPlay) return;
    const interval = setInterval(() => {
      changeSlide(1);
    }, autoPlayTime);

    return () => clearInterval(interval);
  }, [items.length, slide, autoPlay, autoPlayTime, changeSlide]);

  return (
    <div className={clsx('slider', className)} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <Arrows />
      <div className="slider__slides-list" style={{ '--translateX': `-${slide * 100}%` } as CSSProperties}>
        {items.map((slide, index) => (
          <Slide key={index} url={slide} alt={`Зал на ${gym}`} />
        ))}
      </div>
      <Dots />
    </div>
  );
};
