import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
export function getMonth(date = dayjs()) {
  const year = dayjs().year();
  const month = date.month();

  const firstDayOfTheMonth = dayjs(new Date(year, month, 0)).day();
  let currentDayCount = 0 - firstDayOfTheMonth;
  const rowCount = firstDayOfTheMonth === 6 ? 6 : 5;
  const daysMatrix = new Array(rowCount).fill([]).map(() => {
    return new Array(7).fill(0).map(() => {
      currentDayCount++;
      return dayjs(new Date(year, month, currentDayCount));
    });
  });
  return daysMatrix;
}

export function getRange(date = dayjs()) {
  const firstDayOfTheWeekIndex = 1;
  const daysInTheWeek = 7;
  const rangeStart = date.isoWeekday(firstDayOfTheWeekIndex);
  return new Array(daysInTheWeek).fill(0).map((_, i) => {
    const currentDate = rangeStart.add(i, 'day');
    return currentDate;
  });
}
