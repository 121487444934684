import clsx from 'clsx'
import { FC } from 'react'

import { IClass } from 'shared/types'
import { GymPhoto } from 'shared/ui'
import { Interval } from 'shared/ui/interval'
import { LevelInfo } from 'shared/ui/level'
import { Metro } from 'shared/ui/metro'
import { Trainer } from 'shared/ui/trainer'

import './weekClassPreview.scss'

interface IWeekClassPreview {
	readonly classData: IClass
}

export const WeekClassPreview: FC<IWeekClassPreview> = ({ classData }) => {
	const {
		gym,
		date,
		duration,
		trainer,
		is_changed_date,
		is_changed_gym,
		is_changed_level,
		is_changed_trainer,
		level,
	} = classData

	return (
		<div className="week-mode">
			<LevelInfo
				levelTitle={level.title}
				levelColor={level.color}
				is_changed_level={is_changed_level}
			/>
			<GymPhoto img={gym.image} className="week-mode__gym-img" />
			<Metro
				className={clsx(is_changed_gym && 'red-highlight')}
				title={gym.title}
			/>
			<Interval
				start={date}
				duration={duration}
				className={clsx(is_changed_date && 'red-highlight')}
			/>
			<Trainer
				first_name={trainer.first_name}
				last_name={trainer.last_name}
				className={clsx(
					'week_mode_trainer',
					is_changed_trainer && 'red-highlight'
				)}
			/>
		</div>
	)
}
