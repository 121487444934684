import clsx from 'clsx'
import DropDownIcon from 'components/IconComponents/DropDownIcon'
import { FC } from 'react'

import './dropdown.scss'

interface IDropdown {
	readonly isOpen: boolean
	readonly handleClick: () => void
	readonly children: React.ReactNode
	readonly label: React.ReactNode
	readonly active?: boolean
	readonly transition?: 'fade' | 'slide'
	readonly className?: string
}

export const Dropdown: FC<IDropdown> = ({
	isOpen,
	children,
	label,
	handleClick,
	active,
	transition = 'fade',
	className,
}) => {
	return (
		<div className={clsx('dropdown-menu', className)}>
			<button
				onClick={(e) => {
					e.stopPropagation()
					handleClick()
				}}
				className={clsx(`dropdown-menu__btn`, active && 'active')}
			>
				{label}
				<DropDownIcon className={clsx(isOpen && 'open')} />
			</button>
			{
				<ul
					className={clsx(
						'dropdown-menu__wrapper',
						transition,
						isOpen && 'open'
					)}
				>
					{children}
				</ul>
			}
		</div>
	)
}
