import { FC } from 'react';
import { IRoles } from 'shared/types/classTypes';
import { ModalCheckbox } from 'shared/ui/modalCheckbox';
import './joinClassInfo.scss';

interface IRolesList {
  readonly roles: IRoles;
  readonly choosenRole: keyof IRoles | null;
  readonly setChoosenRole: (role: keyof IRoles) => void;
}
export const RolesList: FC<IRolesList> = ({ roles, choosenRole, setChoosenRole }) => {
  const rolesArray = Object.entries(roles) as [`${keyof IRoles}`, number[]][];
  return (
    <>
      <p className="joinClassInfo-container__content__subtitle">
        Выберите <span className="green-highlight">амплуа</span>
      </p>
      <ul className="joinClassInfo-container__content__list">
        {rolesArray.map(([role, [free, count]]) => (
          <li key={role}>
            <ModalCheckbox active={role === choosenRole} disabled={free === 0} onClick={() => setChoosenRole(role)}>
              <div className="joinClassInfo-container__content__list__role">{role.split(' ')[0]}</div>
              <span>{`${count - free}/${count}`}</span>
            </ModalCheckbox>
          </li>
        ))}
      </ul>
    </>
  );
};
