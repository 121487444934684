import { FC } from 'react';
import './slide.scss';
interface ISlide {
  readonly url: string;
  readonly alt: string;
  readonly title?: string;
}

export const Slide: FC<ISlide> = ({
  url,
  title = 'Здесь может быть какой-то уникальный текст выходящий вместе с картинкой',
  alt,
}) => {
  return (
    <div className="slider-slide">
      <img draggable={false} className="slider-slide__img" src={url} alt={alt} />
      {title && <div className="slider-slide__title">{title}</div>}
    </div>
  );
};
