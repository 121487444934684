import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { ProfileCard } from 'entities/profileCard'

import { updatePassword } from 'shared/api/account'
import { Input } from 'shared/input'
import { handleErrorStatus } from 'shared/lib/handleError'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'

import './changePasswordForm.scss'

interface IFormFields {
	password: string
	password_confirmation: string
}

export const ChangePasswordForm = () => {
	const [requestError, setRequestError] = useState<string | null>(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const {
		watch,
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm<IFormFields>({
		mode: 'onChange',
		defaultValues: { password: '', password_confirmation: '' },
	})
	const { mutate: changePasswordMutate, isPending } = useMutation({
		mutationKey: ['changePassword'],
		mutationFn: (data: IFormFields) => updatePassword(data),
		onSuccess: () => {
			setRequestError(null)
			setIsModalOpen(true)
			reset()
		},
		onError: (e: unknown) => {
			const knownError = e as { response: { data: { password: string[] } } }
			if (handleErrorStatus(e) === 400) {
				setRequestError(knownError.response.data.password[0])
			}
		},
	})

	const onSubmit = handleSubmit((data) => {
		changePasswordMutate(data)
	})
	return (
		<section className="change-password-form">
			<ProfileCard>
				<form className="change-password-form__form" onSubmit={onSubmit}>
					<Controller
						name="password"
						control={control}
						rules={{
							minLength: {
								value: 8,
								message: 'Минимальная длина пароля 8 символов',
							},
						}}
						render={({ field }) => (
							<div className="change-password-form__form__control">
								<label
									className="change-password-form__form__control__label"
									htmlFor="password"
								>
									{errors.password || requestError ? (
										<span className="red-highlight">
											{errors.password?.message || requestError}
										</span>
									) : (
										'Придумайте новый пароль'
									)}
								</label>
								<Input
									required
									onFocus={() => setRequestError(null)}
									aria-invalid={!!errors.password || !!requestError}
									type="password"
									{...field}
									placeholder="********"
								/>
							</div>
						)}
					/>
					<p>
						Минимальная длина пароля — 8 символов. Не используйте пароли от
						других сайтов или варианты, которые злоумышленники смогут легко
						подобрать.
					</p>
					<Controller
						name="password_confirmation"
						control={control}
						rules={{
							validate: (value) =>
								value === watch('password') || 'Пароли должны совпадать',
						}}
						render={({ field }) => (
							<div className="change-password-form__form__control">
								<label
									htmlFor="password_confirmation"
									className="change-password-form__form__control__label"
								>
									{errors.password_confirmation ? (
										<span className="red-highlight">
											{errors.password_confirmation.message}
										</span>
									) : (
										<>
											Повторите пароль
											<span className="green-highlight">*</span>
										</>
									)}
								</label>
								<Input
									aria-invalid={!!errors.password_confirmation}
									required
									type="password"
									{...field}
									placeholder="********"
								/>
							</div>
						)}
					/>
					<Button
						type="submit"
						isLoading={isPending}
						disabled={
							!!errors.password ||
							!!errors.password_confirmation ||
							!!requestError
						}
						className="change-password-form__form__btn"
					>
						Сохранить
					</Button>
				</form>
			</ProfileCard>
			{isModalOpen && (
				<Modal
					className="change-password-form-success"
					onClose={() => setIsModalOpen(false)}
				>
					<div className="change-password-form-success__content">
						<h3 className="change-password-form-success__content__title uppercase">
							Изменение пароля
						</h3>
						<p>Ваш пароль был успешно изменен</p>
						<Button theme="green" onClick={() => setIsModalOpen(false)}>
							Понятно
						</Button>
					</div>
				</Modal>
			)}
		</section>
	)
}
