import { useEffect, useState } from 'react';

// Files
import './Social.scss';

// Components
import TelegramIcon from '../IconComponents/TelegramIcon';

// Context
import clsx from 'clsx';
import { api } from 'constants/api';
import YouTubeIcon from '../IconComponents/YouTubeIcon';

const Social = ({ className }) => {
  const [icon, setIcon] = useState([]);
  useEffect(() => {
    const getIcons = async () => {
      const { data } = await api.get('social/');
      setIcon(data);
    };
    getIcons();
  }, []);
  return (
    <div className="social">
      {icon &&
        icon.map(({ id, slug }) => {
          return (
            <a className={clsx(className)} href={slug} key={id} target="_blank" rel="noopener noreferrer">
              {id === 3 ? <TelegramIcon /> : <YouTubeIcon />}
            </a>
          );
        })}
    </div>
  );
};

export default Social;
