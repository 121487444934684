import { FC } from 'react'

interface ITimeIcon {}
export const TimeIcon: FC<ITimeIcon> = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.99998 0C4.02978 0 0 4.02979 0 9C0 13.9701 4.02978 18 8.99998 18C13.9702 18 17.9999 13.9701 17.9999 9C17.9999 4.02979 13.9702 0 8.99998 0ZM9.56252 16.8464V15.7499H8.43748V16.8464C4.54506 16.5698 1.43046 13.455 1.15361 9.56262H2.24998V8.43757H1.15361C1.43046 4.54504 4.54506 1.43043 8.43748 1.15357V2.24994H9.56252V1.15361C13.455 1.43043 16.5695 4.54507 16.8463 8.43761H15.75V9.56265H16.8463C16.5695 13.455 13.4549 16.5698 9.56252 16.8464ZM13.1407 6.60935C13.2176 6.74345 13.1715 6.91599 13.0375 6.99397L10.0663 8.70886C10.092 8.80446 10.125 8.8968 10.125 9C10.125 9.62182 9.62121 10.1249 9.00002 10.1249C8.37864 10.1249 7.87497 9.62182 7.87497 9L4.82192 7.23777C4.5527 7.08185 4.46048 6.73792 4.61588 6.46878C4.77073 6.19853 5.11517 6.10631 5.38435 6.26234L8.45506 8.03551C8.6187 7.94325 8.7978 7.8751 9.00002 7.8751C9.31427 7.8751 9.59698 8.00472 9.80146 8.2135L12.7562 6.50614C12.8881 6.42809 13.0605 6.47424 13.1407 6.60935Z"
				fill="white"
			/>
		</svg>
	)
}
