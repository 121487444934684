import clsx from 'clsx'
import { FC } from 'react'

import './progress.scss'

interface IProgress {
	readonly title: string
	readonly value: number
	readonly maxValue: number
	readonly className?: string
}

export const Progress: FC<IProgress> = ({
	value,
	maxValue,
	title,
	className,
}) => {
	const bar = new Array(maxValue).fill(null)
	return (
		<div className={clsx('progress', className)}>
			<div className="progress__level">
				<p className="progress__level__value">
					{<span className="progress__level__value_current">{value}</span>}
					{`/${maxValue}`}
				</p>
				<div className="progress__level__bar">
					{bar.map((_, idx) => (
						<div
							className={clsx(
								'progress__level__bar__item',
								value > idx && 'filled'
							)}
							key={idx}
						/>
					))}
				</div>
			</div>
			<span>{title}</span>
		</div>
	)
}
