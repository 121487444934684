import { FC } from 'react';
import './trainer.scss';
import { TrainerIcon } from 'shared/icons';
import trainer from './trainer.png';
import clsx from 'clsx';
interface ITrainer {
  readonly first_name: string;
  readonly last_name: string;
  readonly className?: string;
}

export const Trainer: FC<ITrainer> = ({ first_name, last_name, className }) => {
  return (
    <div className={clsx('class-trainer', className)}>
      <TrainerIcon />
      <div className="trainer-name">
        <span>{last_name}</span> <span>{first_name}</span>
      </div>
    </div>
  );
};
