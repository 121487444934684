import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { getMonth } from './zScheduleCal/utils';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import useRange, { getStartEndRange } from './zScheduleCal/useRange';
import { IClass } from 'shared/types';

dayjs.locale('ru');

interface IScheduleStore {
  classes: IClass[];
  scheduleView: 'week' | 'month';
  currentStartRange: dayjs.Dayjs;
  requestParams: string;
  setRequestParams: (requestParams: string) => void;
  setClasses: (classes: IClass[]) => void;
  setCurrentStartRange: (date: dayjs.Dayjs) => void;
  setScheduleView: (scheduleView: 'week' | 'month') => void;
}

export const useScheduleStore = create<IScheduleStore>()(
  persist(
    (set, get) => {
      return {
        classes: [],
        scheduleView: 'week',
        currentStartRange: dayjs(),
        requestParams: getStartEndRange().strParam,
        setRequestParams: (requestParams) => {
          set({ requestParams });
        },
        setClasses: (classes) => set({ classes }),
        setCurrentStartRange: (date) => {
          const params = new URLSearchParams(get().requestParams);
          const { start_date, end_date } = getStartEndRange(date, get().scheduleView);
          params.set('start_date', start_date);
          params.set('end_date', end_date);
          const requestParams = params.toString();
          set({ requestParams: requestParams });
          set({ currentStartRange: date });
        },
        setScheduleView: (scheduleView) => {
          const params = new URLSearchParams(get().requestParams);
          const { start_date, end_date } = getStartEndRange(get().currentStartRange, scheduleView);
          params.set('start_date', start_date);
          params.set('end_date', end_date);
          const requestParams = params.toString();
          set({ requestParams });
          set({ scheduleView });
        },
      };
    },
    { name: 'Schedule', storage: createJSONStorage(() => sessionStorage) }
  )
);
