import { IAuthResponse, IUser } from 'shared/api/account';
import { STORE_ACCESS_TOKEN_NAME, STORE_REFRESH_TOKEN_NAME, STORE_USER_DATA_NAME } from 'shared/constants';

// const key = new TextEncoder().encode(process.env.SECRET ?? 'secret');

// export async function encrypt(payload: any) {
//   return new SignJWT(payload).setProtectedHeader({ alg: 'HS256' }).setIssuedAt().setExpirationTime('100days').sign(key);
// }
// export async function decrypt(session: string): Promise<any> {
//   const { payload } = await jwtVerify(session, key, {
//     algorithms: ['HS256'],
//   });
//   return payload;
// }
export function createSession(data: IAuthResponse) {
  const { access, refresh } = data;

  localStorage.setItem(STORE_ACCESS_TOKEN_NAME, access);
  if (refresh) {
    localStorage.setItem(STORE_REFRESH_TOKEN_NAME, refresh);
  }
}
export const getSession = () => {
  const session = localStorage.getItem(STORE_USER_DATA_NAME);
  if (!session) return null;
  return JSON.parse(session) as IUser;
};
export const clearSession = () => {
  localStorage.removeItem(STORE_ACCESS_TOKEN_NAME);
  localStorage.removeItem(STORE_REFRESH_TOKEN_NAME);
  localStorage.removeItem(STORE_USER_DATA_NAME);
};
