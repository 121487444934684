import { FC } from 'react';

import { Dot } from './dot';
import './dots.scss';
import { useSliderStore } from 'entities/slider/model/slider-store';

interface IDots {}

export const Dots: FC<IDots> = () => {
  const slides = useSliderStore((state) => state.items);
  return (
    <div className="slider-dots">
      {slides.map((_, index) => (
        <Dot slideNumber={index} key={`dot-${index}`} />
      ))}
    </div>
  );
};
