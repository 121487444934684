import { SwipeableDrawer } from '@mui/material';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { MdFilterList, MdOutlineClose } from 'react-icons/md';
import { ScheduleFilter } from './scheduleFilter';
import { useScheduleStore } from 'pages/SchedulePage/schedule-store';
import { ToggleButton } from 'shared/ui/toggleButton';

interface IScheduleFilterMob {
  readonly clearFilter: () => void;
  readonly isFilterActive: boolean;
  readonly params: URLSearchParams;
  readonly setRequestParams: (requestParams: string) => void;
}

export const ScheduleFilterMob: FC<IScheduleFilterMob> = ({
  clearFilter,
  isFilterActive,
  params,
  setRequestParams,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <>
      <ToggleButton
        onClick={() => setIsOpen(true)}
        handleCloseIcon={(e) => {
          e.stopPropagation();
          clearFilter();
        }}
        active={isFilterActive}
        CloseIcon={MdOutlineClose}
        Icon={MdFilterList}
        className="mob-trigger-btn"
      >
        Фильтры
      </ToggleButton>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <div className="title">
          <MdFilterList />
          <span>Фильтры</span>
        </div>
        <ScheduleFilter
          transition="slide"
          isFilterMobile
          clearFilter={clearFilter}
          params={params}
          setRequestParams={setRequestParams}
          isFilterActive={isFilterActive}
        />
      </SwipeableDrawer>
    </>
  );
};
