import { clsx } from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { FC, MouseEvent } from 'react'

import { useScheduleStore } from '../../../schedule-store'
import { getDayClasses } from '../../getDayClasses'
import useRange from '../../useRange'
import { useResize } from '../../useResize'
import MonthDayClass from '../dayClass/MonthDayClass'
import './monthDay.scss'

interface IMonthDay {
	readonly day: Dayjs
	readonly currentStartRange: Dayjs
}

const MonthDay: FC<IMonthDay> = ({ day, currentStartRange }) => {
	const { classes, setCurrentStartRange, setScheduleView } = useScheduleStore(
		({
			scheduleView,
			classes,
			setCurrentStartRange,
			requestParams,
			setScheduleView,
		}) => ({
			scheduleView,
			setScheduleView,
			setCurrentStartRange,
			requestParams,
			classes,
		})
	)
	const { isMobileScreen, isTabletScreen } = useResize()
	const toSelectedDay =
		(hasClasses: boolean) =>
		({ currentTarget }: MouseEvent<HTMLDivElement>) => {
			if (!hasClasses) {
				return
			}
			const { id } = currentTarget
			setCurrentStartRange(dayjs(id))
			if (isTabletScreen) {
				const element = document.getElementById('small-class-container')
				if (element) {
					element.scrollIntoView({ block: 'end', behavior: 'smooth' })
				}
			} else {
				window.scroll({
					behavior: 'smooth',
					top: 100,
					left: 0,
				})
			}
		}

	const dayClasses = getDayClasses(classes, day)
	const hasClasses = dayClasses?.length > 0
	const isPastDate = day.isBefore(dayjs(), 'day')
	const isCurrentMonth = day.isSame(dayjs(currentStartRange), 'month')
	const isToday = day.isSame(dayjs(), 'day')
	const isSelected = day.isSame(currentStartRange)
	return (
		<div
			className={clsx(
				'month-day',
				hasClasses && 'has-classes',
				isToday && isCurrentMonth && 'month-today',
				isMobileScreen && 'mobile',
				isSelected && 'selected',
				isPastDate && 'propagation-disabled'
			)}
			id={day.format('YYYY-MM-DD')}
			onClick={toSelectedDay(hasClasses)}
		>
			{isCurrentMonth && (
				<div className="date">
					<span className={clsx({ today: isToday })}>{day.format('D')}</span>
				</div>
			)}
			{dayClasses?.length > 0 && (
				<div className={clsx('classes')}>
					{dayClasses?.map((classData, idx) => (
						<MonthDayClass
							key={classData.id}
							classData={classData}
							idx={idx}
							classesCount={dayClasses?.length}
						/>
					))}
				</div>
			)}
			{isPastDate && <div className="past-date fade-in"></div>}
		</div>
	)
}

export default MonthDay
