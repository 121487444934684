import { FC } from 'react';

interface IEyeIcon {
  readonly shown: boolean;
}

export const EyeIcon: FC<IEyeIcon> = ({ shown }) => {
  return shown ? (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8358 20.2833C16.9138 20.3613 16.8821 20.4978 16.7765 20.5319C10.5203 22.5356 3.7505 19.3108 1.66888 13.2666C1.61037 13.0959 1.61037 12.9058 1.66888 12.7344C2.27256 10.9818 3.28575 9.4462 4.58413 8.20632C4.63369 8.1592 4.7125 8.16082 4.76044 8.20876L8.25581 11.7041C8.28019 11.7285 8.28912 11.7626 8.281 11.7959C7.37425 15.3263 10.6763 18.6169 14.1928 17.7223C14.2326 17.7118 14.2699 17.7191 14.2992 17.7483C14.5901 18.0384 16.2858 19.7333 16.8358 20.2833Z"
        fill="white"
      />
      <path
        d="M20.0527 18.9038C22.0076 17.5291 23.531 15.5896 24.3313 13.2643C24.3898 13.0936 24.3898 12.9068 24.3313 12.7361C22.7128 8.0342 18.1596 4.8752 13.0002 4.8752C10.986 4.8752 9.06689 5.36027 7.37689 6.22801L3.01214 1.86327C2.69445 1.54558 2.18095 1.54558 1.86327 1.86327C1.54558 2.18095 1.54558 2.69445 1.86327 3.01214L22.9883 24.1371C23.306 24.4548 23.8195 24.4548 24.1371 24.1371C24.4548 23.8195 24.4548 23.306 24.1371 22.9883L20.0527 18.9038ZM13.0002 8.1252C15.6888 8.1252 17.8752 10.3116 17.8752 13.0002C17.8752 14.0516 17.5372 15.0233 16.9693 15.8204L10.18 9.03114C10.9771 8.4632 11.9488 8.1252 13.0002 8.1252Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9906 21C7.48547 21 3.09269 17.6118 1 12.5C3.11155 7.38825 7.50432 4 12.9906 4C18.4768 4 22.8885 7.38825 25 12.5C22.8885 17.6118 18.4768 21 12.9906 21ZM12.9906 18.9631C17.3456 18.9631 21.0974 16.3191 22.8507 12.5C21.0974 8.68088 17.3456 6.03687 12.9906 6.03687C8.65436 6.03687 4.90259 8.68088 3.14925 12.5C4.88374 16.3191 8.63551 18.9631 12.9906 18.9631ZM8.42812 12.5C8.42812 9.87558 10.4643 7.76037 12.9717 7.76037C15.498 7.76037 17.5342 9.89516 17.5342 12.5C17.5342 15.1244 15.4792 17.2396 12.9717 17.2396C10.4643 17.2396 8.42812 15.1048 8.42812 12.5ZM13.2545 11.3053C13.2545 12.1279 13.8767 12.7938 14.6874 12.7938C15.4603 12.7938 16.1013 12.1279 16.1013 11.3053C16.1013 10.5023 15.4792 9.83641 14.6874 9.83641C13.8955 9.83641 13.2545 10.4827 13.2545 11.3053Z"
        fill="white"
      />
    </svg>
  );
};
