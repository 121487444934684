import clsx from 'clsx';
import { FC } from 'react';
import './iconTitle.scss';

interface IIconTitle {
  readonly className?: string;
  readonly Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  readonly title: string;
}

export const IconTitle: FC<IIconTitle> = ({ className, Icon, title }) => {
  return (
    <div className={clsx(className, 'icon-title')}>
      <Icon />
      <span>{title}</span>
    </div>
  );
};
