export const formatPhone = (
	phone: string,
	to = 'phone-format' as 'numbers' | 'phone-format'
) => {
	let regexp = / /
	let replacement = ''
	if (to === 'phone-format') {
		regexp = /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/
		replacement = '$1 ($2) $3-$4-$5'
	}
	if (to === 'numbers') {
		regexp = /[^+\d]/gm
	}
	return phone.replace(regexp, replacement)
}
