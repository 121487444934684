import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// Files
import { api, apiHostName } from '../../constants/api';
import './MainPage.scss';

// Components
import Image from '../../components/Image/Image';
import Loader from '../../components/Loader/Loader';
import Review from '../../components/Review/Review';
import Statistics from '../../components/Statistics/Statistics';
import YMap from '../../components/YMap/YMap';
import Birthday from './Birthday/Birthday';
import InfoBlock from './InfoBlock/InfoBlock';
import InfoCards from './InfoCards/InfoCards';

// Context
import { useSessionStore } from 'shared/model';

const MainPage = () => {
  const { session, setIsPopupAccountOpen } = useSessionStore(({ session, setIsPopupAccountOpen }) => ({
    session,
    setIsPopupAccountOpen,
  }));

  const [mainPageInfo, setMainPageInfo] = useState([]); // Main page info
  const [isLoader, setIsLoader] = useState(true); // Loader
  const [mainPageReviews, setMainPageReviews] = useState([]); // Main page reviews
  const [isNextReviews, setIsNextReviews] = useState(null); // Show more reviews button state

  let bannerBg; // Переменная для банера
  let reviewsOffsetRef = useRef(0); // начальное значение для отзывов
  let reviewsOffsetStep = 3; // шаг для подгрузки обтзывов

  useEffect(() => {
    const body = document.querySelector('body');

    body.classList.add('no-scroll'); // убрать скролл для страницы на вермя лоадера

    // Получение данных страницы
    api
      .get('home-page/')
      .then(({ data }) => setMainPageInfo(data))
      .catch((error) => console.error(error));

    // Получение отзывов для страницы
    api
      .get(`reviews/?limit=3&offset=${reviewsOffsetRef.current}&target=s`)
      .then(({ data }) => {
        // скрыть показать кнопку показа больше отзывов
        data.next ? setIsNextReviews(true) : setIsNextReviews(false);

        const filteredMainPageReviews = data.results.filter((item) => item.is_published === true);

        setMainPageReviews(filteredMainPageReviews);
      })
      .catch((error) => console.error(error));

    setTimeout(() => {
      body.classList.remove('no-scroll'); // вернуть скролл для страницы на после лоадера

      setIsLoader(false);
    }, 2400);
  }, []);

  /*** Handlers ***/
  const handleAddReviews = () => {
    reviewsOffsetRef.current = reviewsOffsetRef.current + reviewsOffsetStep; // увеличение шага подгрузки отзывов

    // Получение следующих отзывов для страницы
    api
      .get(`reviews/?limit=3&offset=${reviewsOffsetRef.current}&target=s`)
      .then(({ data }) => {
        // скрыть показать кнопку показа больше отзывов
        data.next ? setIsNextReviews(true) : setIsNextReviews(false);

        const filteredMainPageReviews = data.results.filter((item) => item.is_published === true);

        setMainPageReviews((prev) => [...prev, ...filteredMainPageReviews]);
      })
      .catch((error) => console.error(error));
  };

  // Вставляем видео файл, видео код или картинку
  if (mainPageInfo?.banner_video) {
    // controls добавляет элементы управления для <video>
    bannerBg = <video src={`${apiHostName}${mainPageInfo?.banner_video}`} autoPlay muted loop />;
  } else if (mainPageInfo?.banner_video_code) {
    bannerBg = (
      <iframe
        src={mainPageInfo?.banner_video_code}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  } else {
    const imgMobSrc = mainPageInfo?.banner_image_mob;
    const imgDeskSrc = mainPageInfo?.banner_image;

    bannerBg = <Image mob={imgMobSrc} desk={imgDeskSrc} alt="main banner" title="main banner" />;
  }

  return (
    <>
      {/*** Loader ***/}
      {isLoader && <Loader />}

      {/*** Main banner ***/}
      <div className="main-banner media-wrapper">
        {bannerBg}
        <div className="main-banner__content-wrapper">
          <div className="main-banner__content container">
            <h2 className="main-banner__title">{mainPageInfo?.banner_title}</h2>
            <p>{mainPageInfo?.banner_description}</p>

            <div className="main-banner__content-action">
              <Link className="btn btn--bg" to={mainPageInfo?.banner_link_url}>
                {mainPageInfo?.banner_link_title}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/*** Main content ***/}
      <div className="main-content">
        <div className="container">
          {/*** Statistic ***/}
          <div className="main-content__item">
            <Statistics />
          </div>

          {/*** Info block ***/}
          <div className="main-content__item">
            <InfoBlock data={mainPageInfo} />
          </div>

          {/*** Info cards ***/}
          <div className="main-content__item">
            <InfoCards data={mainPageInfo} />
          </div>

          {/*** Slider ***/}
          {/*<div className="main-content__item">*/}
          {/*  <div className="slider-block main-slider">*/}
          {/*    <h2 className="page-title">Галерея</h2>*/}

          {/*    <MainSlider slides={mainPageInfo.slides} />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*** Birthday ***/}
          {session && <Birthday />}

          {/*** Reviews ***/}
          <div className="main-content__item">
            <h2 className="page-title">Отзывы</h2>

            {mainPageReviews.length > 0 ? (
              <ul className="reviews">
                {mainPageReviews.map((item) => {
                  return (
                    <li key={item.id} className="reviews__item">
                      <Review item={item} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="no-reviews">Отзывов нет</p>
            )}

            {/* показать еще отзывы */}
            {isNextReviews && (
              <button className="btn add-review" onClick={handleAddReviews}>
                смотреть ещё отзывы
              </button>
            )}

            {/* написать отзывы */}
            {/* <button
              className="btn btn--bg add-review"
              onClick={() => {
                if (!session) {
                  setIsPopupAccountOpen(true);
                  return;
                }

                toggleBodyScrollHandler();

                setIsCommentPopupForm(true);
                setAddCommentPopupOpen(true);
              }}
            >
              написать отзыв
            </button> */}
          </div>
        </div>

        {/*** Map ***/}
        <section className="map-section">
          <div className="container">
            <h2 className="page-title">контакты</h2>
          </div>

          <div className="map">
            <YMap />
          </div>
        </section>
      </div>

      {/*** Comment popup ***/}
      {/* <CommentPopup previousComments={mainPageReviews} /> */}
    </>
  );
};

export default MainPage;
