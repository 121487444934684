import { useEffect, useState } from 'react';

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isMobileScreen = width <= 768;
  const TABLET_W = width <= 1024 && width > 768;
  const isTabletScreen = width <= 1024;
  const LARGE_W = width >= 1024 && width < 1440;
  const isLargeScreen = width >= 1024;
  const isXLargeScreen = width >= 1440;
  const isMediumScreen = width <= 1250;
  return {
    width,
    isMobileScreen,
    LARGE_W,
    isXLargeScreen,
    TABLET_W,
    isLargeScreen,
    isTabletScreen,
    isMediumScreen,
  };
};
