import { clsx } from 'clsx'
import { useCallback, useEffect, useRef, useState } from 'react'
import { IconType } from 'react-icons'
import { TbCalendarMonth, TbCalendarWeek } from 'react-icons/tb'

import { Dropdown } from 'shared/ui/dropdown'
import { ToggleButton } from 'shared/ui/toggleButton'

import { useScheduleStore } from '../../schedule-store'
import { useResize } from '../useResize'
import './switchView.scss'

const views: { type: 'week' | 'month'; name: string; icon: IconType }[] = [
	{ type: 'week', name: 'Неделя', icon: TbCalendarWeek },
	{ type: 'month', name: 'Месяц', icon: TbCalendarMonth },
]
export default function SwitchView() {
	const { isTabletScreen } = useResize()
	const { scheduleView, setScheduleView, setCurrentStartRange } =
		useScheduleStore(
			({ setScheduleView, scheduleView, setCurrentStartRange }) => ({
				setScheduleView,
				scheduleView,
				setCurrentStartRange,
			})
		)
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const activeMode = views.find((view) => view.type === scheduleView)
	const ActiveIcon = activeMode?.icon ?? TbCalendarMonth
	const handleClose = useCallback(() => {
		if (!isDropdownOpen) return
		setIsDropdownOpen(false)
	}, [isDropdownOpen])
	const handleChooseType = (type: 'week' | 'month') => {
		setScheduleView(type)
		handleClose()
	}
	useEffect(() => {
		document.addEventListener('click', handleClose)
		return () => {
			document.removeEventListener('click', handleClose)
		}
	}, [handleClose])
	return (
		<>
			{isTabletScreen ? (
				<>
					<Dropdown
						className={clsx(
							'switch-view-dropdown',
							isDropdownOpen && 'open-switch'
						)}
						isOpen={isDropdownOpen}
						handleClick={() => setIsDropdownOpen(!isDropdownOpen)}
						label={
							<div className="switch-view-dropdown__label">
								<ActiveIcon /> <span>{activeMode?.name}</span>
							</div>
						}
					>
						{views.map((view) => (
							<li key={view.type}>
								<ToggleButton
									className="switch-view-dropdown__btn"
									onClick={() => handleChooseType(view.type)}
									active={scheduleView === view.type}
									Icon={view.icon}
								>
									{view.name}
								</ToggleButton>
							</li>
						))}
					</Dropdown>
				</>
			) : (
				<div className="switch-view">
					{views.map((view) => {
						const Icon = view.icon
						return (
							<ToggleButton
								key={view.type}
								onClick={() => setScheduleView(view.type)}
								active={scheduleView === view.type}
								Icon={Icon}
							>
								{view.name}
							</ToggleButton>
						)
					})}
				</div>
			)}
		</>
	)
}
