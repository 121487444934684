import { FC } from 'react'

import { ProfileCard } from 'entities/profileCard'

import { IUserData } from 'shared/api/account'
import { formatDate } from 'shared/lib/utils'

import './userDescription.scss'

interface IUserDescription {
	readonly data: IUserData
}

export const UserDescription: FC<IUserDescription> = ({ data }) => {
	const descriptionInfo = [
		{ title: 'Логин', value: data.username ?? '-' },
		{ title: 'Рост', value: data.height ? `${data.height} см` : '-' },
		{
			title: 'День рождения',
			value: data.birthday ? formatDate(data.birthday) : '-',
		},
	]
	const subscribe = data.balance[0]
	const subscribeDuration = subscribe.date_end
		? formatDate(subscribe.date_end)
		: '∞'
	const nearClass = data.near_class ? formatDate(data.near_class.date) : '-'
	return (
		<div className="user-description">
			<article>
				<ProfileCard className="user-description__wrapper">
					<h3 className="user-description__title">Об игроке</h3>
					<ul className="user-description__content">
						{descriptionInfo.map(({ title, value }) => (
							<li key={title} className="user-description__content__item">
								<span>{title}</span>
								<span>{value}</span>
							</li>
						))}
						<li>
							<p>О себе</p>
							<p className="user-description__content__item__about">
								{data.about ? data.about : 'Информация отсутствует'}
							</p>
						</li>
					</ul>
				</ProfileCard>
			</article>
			<article>
				<ProfileCard>
					<h3 className="user-description__title">Тренировки</h3>
					<ul className="user-description__content">
						<li className="user-description__content__item">
							<span>Ближайшая</span>
							<span>{nearClass}</span>
						</li>
						<li className="user-description__content__item">
							<span>Вид абонемента</span>
							<span>{subscribe.product}</span>
						</li>
						<li className="user-description__content__item">
							<span>Срок действия</span>
							<span>{subscribeDuration}</span>
						</li>
					</ul>
				</ProfileCard>
			</article>
		</div>
	)
}
