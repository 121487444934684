import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect } from 'react'
import { MoonLoader } from 'react-spinners'

import ScheduleClass from 'pages/SchedulePage/ScheduleClass/ScheduleClass'
import { useScheduleStore } from 'pages/SchedulePage/schedule-store'

import { getDayClasses } from '../getDayClasses'
import { useResize } from '../useResize'
import { getRange } from '../utils'
import './week.scss'

interface IWeek {
	currentStartRange: dayjs.Dayjs
	isLoading: boolean
}
const Week: FC<IWeek> = ({ currentStartRange, isLoading }) => {
	const daysRange = getRange(currentStartRange)
	const { scheduleView, classes } = useScheduleStore(
		({ scheduleView, classes }) => ({ scheduleView, classes })
	)
	const { isXLargeScreen } = useResize()
	const element = document.getElementById('today')
	const isToday = (day: Dayjs) => day.isSame(dayjs(), 'day')
	const isSelected = (day: Dayjs) => currentStartRange.day() === day.day()
	useEffect(() => {
		if (!isXLargeScreen) {
			element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
		} else {
			document.body.scrollIntoView({ block: 'start', behavior: 'smooth' })
		}
	}, [scheduleView, element, isXLargeScreen])
	const renderWeekDay = (day: Dayjs) => {
		const dayClasses = getDayClasses(classes, day)
		return (
			<div key={day.toString()} className="week-class-card fade-in">
				{dayClasses?.length > 0
					? dayClasses.map((classData) => (
							<ScheduleClass key={classData.id} classData={classData} />
					  ))
					: !isXLargeScreen && (
							<p className="fade-in week-class-card__no-classes">
								Нет тренировок
							</p>
					  )}
			</div>
		)
	}
	return (
		<div>
			{isXLargeScreen && (
				<div className="week-days">
					{daysRange.map((day) => (
						<div
							key={day.toString()}
							className={clsx(
								'date',
								isToday(day) && 'today',
								isSelected(day) && 'selected'
							)}
						>
							<span className="capitalize">{dayjs(day).format('dd')},</span>
							<span>{dayjs(day).format('D MMM')}</span>
						</div>
					))}
				</div>
			)}
			<div className={'week-view'}>
				{isLoading ? (
					<div className="loader">
						<MoonLoader color="#adff2f" aria-label="Loading Spinner" />
					</div>
				) : (
					daysRange.map((day) =>
						!isXLargeScreen ? (
							<div
								key={day.toString()}
								id={day.isSame(dayjs(), 'day') ? 'today' : ''}
								className="week-mobile-mode fade-in"
							>
								<div className="weekday-mobile-item">
									<p className="capitalize">{day.format('dd')}</p>
									<p>{day.format('D')}</p>
									<p>{day.format('MMM')}</p>
								</div>
								{renderWeekDay(day)}
							</div>
						) : (
							renderWeekDay(day)
						)
					)
				)}
			</div>
		</div>
	)
}

export default Week
