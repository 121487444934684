import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getClassTime = (start: string, duration: number): string => {
	const startDayjs = dayjs(start).tz('Europe/Moscow')
	const classStart = startDayjs.format('HH:mm')
	const classEnd = startDayjs.add(duration, 'm')
	const timeRange = `${classStart} - ${classEnd.format('HH:mm')}`

	return timeRange
}
