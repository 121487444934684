import { STORE_REFRESH_TOKEN_NAME } from 'shared/constants'

import { api, tokenApi } from '../base'
import type {
	IAuthFields,
	IAuthResponse,
	ISettingFields,
	IUser,
	IUserData,
	IUserRole,
} from './types'

export const updateToken = () => {
	const refresh = localStorage.getItem(STORE_REFRESH_TOKEN_NAME)

	return api.post<IAuthResponse>(`token/refresh/`, { refresh })
}

export const login = (data: IAuthFields) => {
	return api.post<IAuthResponse>('token/', data)
}

export const getUserInfo = () => {
	return tokenApi.get<IUser>('get_my_id/')
}

export const getUserProfile = () => {
	return tokenApi.get<IUserData>('profile/')
}
export const updateUserProfile = (body: ISettingFields) => {
	return tokenApi.patch<Partial<IUserData>>('profile/', body)
}

export const updatePassword = (data: {
	password: string
	password_confirmation: string
}) => {
	return tokenApi.patch('change-password/', data)
}

export const getRoles = () => {
	return api.get<IUserRole[]>('role/')
}
