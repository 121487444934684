import clsx from 'clsx';
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';
import './input.scss';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {}

const InputComponent = (props: IInput, ref: ForwardedRef<HTMLInputElement>) => {
  const { type = 'text', className, ...rest } = props;
  return <input type={type} ref={ref} {...rest} className={clsx('form-input', className)} />;
};

export const Input = forwardRef(InputComponent);
