import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useEffect, useState } from 'react'

// Context
import { USER_LEVELS } from 'shared/constants/levels'
import { getSession } from 'shared/lib/auth'
import { Level } from 'shared/ui/level/level'

// Files
import { api } from '../../constants/api'
import './SchedulePage.scss'
import { useScheduleStore } from './schedule-store'
import Month from './zScheduleCal/Month/Month'
import Week from './zScheduleCal/Week/Week'
import { useClassesQuery } from './zScheduleCal/api/classes'
import ScheduleHeader from './zScheduleCal/scheduleHeader/ScheduleHeader'
import useRange from './zScheduleCal/useRange'
import { useResize } from './zScheduleCal/useResize'

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
	monthsShort: [
		'янв',
		'фев',
		'мар',
		'апр',
		'мая',
		'июн',
		'июл',
		'авг',
		'сен',
		'окт',
		'ноя',
		'дек',
	],
})

const SchedulePage = () => {
	// Page
	const [pageInfo, setPageInfo] = useState<{
		title: string
		image_mob: string
		image: string
	}>() // Page title and subtitle info state
	const [userId, setUserId] = useState<number | null>(null)
	const user = getSession()?.id
	useEffect(() => {
		setUserId(user ? user : null)
	}, [user])
	const {
		setClasses,
		scheduleView,
		requestParams,
		setScheduleView,
		setCurrentStartRange,
	} = useScheduleStore(
		({
			setClasses,
			requestParams,
			setScheduleView,
			scheduleView,
			setCurrentStartRange,
		}) => ({
			setClasses,
			requestParams,
			setScheduleView,
			scheduleView,
			setCurrentStartRange,
		})
	)
	const { isLargeScreen, isMediumScreen } = useResize()
	const {
		data: classes,
		isLoading,
		isSuccess,
	} = useClassesQuery(requestParams, userId || 'guest')

	useEffect(() => {
		if (isSuccess) {
			setClasses(classes)
		}
	}, [classes, isSuccess, setClasses])

	useEffect(() => {
		// Set page info
		api.get('schedule-page/').then(({ data }) => setPageInfo(data))
	}, [])
	const { currentStartRange } = useRange()

	useEffect(() => {
		if (isMediumScreen && scheduleView === 'week') {
			return
		} else {
			window.scroll({
				behavior: 'smooth',
				top: 100,
				left: 0,
			})
		}
	}, [scheduleView, isMediumScreen])
	const renderSchedule = () => {
		const components = {
			week: (
				<Week currentStartRange={currentStartRange} isLoading={isLoading} />
			),
			month: <Month currentStartRange={currentStartRange} />,
		}
		return components[scheduleView]
	}
	return (
		<section className="schedule">
			{/*** Page heading ***/}
			<article>
				<h1 className="title">{'Расписание'}</h1>
				{/* {isLargeScreen && (
          <div className="schedule-ad-wrapper">
            <div className="schedule-ad-image">
              <img src={pageInfo?.image} alt="Объявление" />
            </div>
            <ul className="levels">
              {USER_LEVELS.map(({ colors, title }) => {
                return (
                  <li className="level" key={title}>
                    <Level colors={colors} width={6} />
                    <span>{`${title} уровень`}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )} */}
			</article>
			<article id="schedule">
				<ScheduleHeader />
				<div className="classes">{renderSchedule()}</div>
			</article>

			{/*** Class reviews popup ***/}
			{/* <ScheduleClassReviews /> */}
		</section>
	)
}

export default SchedulePage
