import { ISubscribe } from 'shared/types';
import { tokenApi } from '../base';
import { IGroupProduct, IPay, IProduct } from './types';

export const getProducts = async () => {
  const response = await tokenApi.get<IProduct[]>('product/');
  return response;
};

export const getGroupProducts = async () => {
  const response = await tokenApi.get<IGroupProduct[]>('type-training/with_products/');
  return response;
};

export const getProductById = async (id: number) => {
  const response = await tokenApi.get<IProduct>(`product/${id}`);
  return response;
};

export const getLinkToPay = async (id: number) => {
  const response = await tokenApi.get<IPay>(`pay/${id}`);
  return response;
};

export const getProductsForClass = async (id: number) => {
  const response = await tokenApi.get<IProduct[]>(`product/get_products_for_klass/${id}`);
  return response;
};

export const freezeSubscribe = async (id: number) => {
  const response = await tokenApi.post<ISubscribe>(`freeze_product_on/${id}/`, {});
  return response;
};
export const unFreezeSubscribe = async (id: number) => {
  const response = await tokenApi.post<ISubscribe>(`freeze_product_off/${id}/`, {});
  return response;
};
