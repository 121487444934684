import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import { PROFILE_LINKS } from 'shared/constants'

import { useUserProfileQuery } from '../api/userProfile'
import './profile.scss'

interface IProfile {}

export const Profile: FC<IProfile> = () => {
	const { pathname } = useLocation()
	const {
		data: userProfile,
		isSuccess,
		isPending,
		isError,
	} = useUserProfileQuery()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="profile">
			<aside className="profile__aside">
				<ul className="profile__aside__links">
					{PROFILE_LINKS.map(({ title, path, Icon }) => (
						<li key={path}>
							<Link
								to={path}
								className={clsx(
									'profile__aside__links__item',
									pathname === path && 'active'
								)}
							>
								<Icon />
								<span>{title}</span>
							</Link>
						</li>
					))}
				</ul>
			</aside>
			{isSuccess && !isPending && <Outlet context={{ userProfile }} />}
			{isPending && <div>Loading...</div>}
		</div>
	)
}
