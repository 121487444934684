import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { getClassById, getClasses } from 'shared/api/class';

export const useClassesQuery = (params: string, userId: number | 'guest') => {
  return useQuery({
    queryKey: [params, userId],
    queryFn: () => getClasses(params),
    staleTime: 60000 * 5,
  });
};
