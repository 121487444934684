import { FC } from 'react';
import { ArrowBlackBg } from 'shared/icons';

import './arrows.scss';
import { useSliderStore } from 'entities/slider/model/slider-store';

interface IArrows {}

export const Arrows: FC<IArrows> = () => {
  const changeSlide = useSliderStore((state) => state.changeSlide);
  return (
    <div className="slider-arrows">
      <button className="slider-arrows__left" onClick={() => changeSlide(-1)}>
        <ArrowBlackBg />
      </button>
      <button className="slider-arrows__right" onClick={() => changeSlide(1)}>
        <ArrowBlackBg />
      </button>
    </div>
  );
};
