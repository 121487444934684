import { FC } from 'react';

interface ICloseIcon {
  readonly className?: string;
}

export const CloseIcon: FC<ICloseIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 19.5556L19.5556 0L22 2.44444L2.44444 22L0 19.5556Z" fill="white" />
      <path d="M19.5556 22L0 2.44444L2.44444 0L22 19.5556L19.5556 22Z" fill="white" />
    </svg>
  );
};
