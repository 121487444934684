// Components
// Context
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
	JoinClassInfo,
	useJoinClassMutate,
	useJoinClassStore,
	usePreJoinClassMutate,
} from 'features/joinClass'

import { leaveClass } from 'shared/api/class'
import { clearSession } from 'shared/lib/auth/sessions'
import { handleErrorStatus, toastError } from 'shared/lib/handleError'
import { useSessionStore } from 'shared/model'
import { IClass } from 'shared/types'
import { IRoles } from 'shared/types/classTypes'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'

import { WeekClassPreview } from '../zScheduleCal/Week/weekClassPreview/weekClassPreview'
// Files
import './ScheduleClass.scss'

dayjs.locale('ru')

interface IScheduleClass {
	classData: IClass
}
const ScheduleClass: FC<IScheduleClass> = ({ classData }) => {
	const navigate = useNavigate()

	const { session, setSession, setIsPopupAccountOpen } = useSessionStore(
		({ session, setSession, setIsPopupAccountOpen }) => ({
			session,
			setSession,
			setIsPopupAccountOpen,
		})
	)
	const {
		id: classId,
		date,
		free_remove_to_klass,
		limit,
		players,
		roles_available,
		is_role_mode,
	} = classData
	const isPlayerAlreadyJoined = session ? players.includes(session.id) : false
	const [joined, setJoined] = useState(isPlayerAlreadyJoined) // Состояние записи пользователя
	const [isModalOpen, setIsModalOpen] = useState(false)
	/** Количество игроков занятия */
	const [playersCount, setPlayersCount] = useState(players.length)

	const [resetStore, setErrorMessage] = useJoinClassStore((state) => [
		state.reset,
		state.setErrorMessage,
	])
	const handleCloseModal = () => {
		setIsModalOpen(false)
		resetStore()
	}
	const handleOpenModal = () => {
		setIsModalOpen(true)
	}

	const {
		mutate: mutateJoinClass,
		isPending: isJoinClassLoading,
		isError: isJoinClassError,
	} = useJoinClassMutate(classId, setPlayersCount, setJoined, handleCloseModal)
	const { mutate: mutatePreJoinClass, isPending: isPreJoinClassLoading } =
		usePreJoinClassMutate(classId, date, handleOpenModal, mutateJoinClass)

	const { mutate: mutateLeaveClass, isPending: isLeaveClassLoading } =
		useMutation({
			mutationKey: ['leave_class', classId],
			mutationFn: leaveClass,
			onSuccess: (data) => {
				if (!data.free) {
					setErrorMessage(data.message)
					setIsModalOpen(true)
				}
				setPlayersCount(data.players_count)
				setJoined(false)
			},
			onError: (error) => {
				toastError(error)
				// Logout user
				if (handleErrorStatus(error) === 401) {
					clearSession()
					setJoined(false)
					setSession(null)
					setIsPopupAccountOpen(true) // Open login popup
				}
			},
		})
	const { state } = useLocation()
	useEffect(() => {
		const delayed = sessionStorage.getItem('delayed_appointment')

		const isFromSuccess = state?.from === 'payment-success'
		if (delayed && isFromSuccess) {
			const delayed_data = JSON.parse(delayed) as {
				classId: number
				role: keyof IRoles
			}
			if (delayed_data.classId === classId) {
				const schedule = document.querySelector('.schedule')
				schedule?.scrollIntoView({ behavior: 'smooth' })
				mutatePreJoinClass({
					classId,
					is_role_mode,
					delayed_join: true,
					role: delayed_data.role,
				})
				sessionStorage.removeItem('delayed_appointment')
			}
		}
	}, [])
	useEffect(() => {
		if (session && players.includes(session.id)) {
			setJoined(true)
		} else {
			setJoined(false)
		}
	}, [players, session])

	const freeRemoveDeadline = dayjs(date).subtract(free_remove_to_klass, 'hour')
	const isEndedClass = dayjs(date).diff(dayjs()) < 0
	const crowded = playersCount >= limit

	const isFreeRemoveAvailable = dayjs().isBefore(freeRemoveDeadline)
	return (
		<>
			<article
				className={clsx('schedule-class', isEndedClass && 'ended-class')}
			>
				<WeekClassPreview classData={classData} />
				{!joined && (
					<div className="schedule-class__join-class">
						<Button
							disabled={crowded}
							className={'schedule-class__join-class__btn'}
							isLoading={
								(isJoinClassLoading && !isJoinClassError) ||
								(isPreJoinClassLoading && !isJoinClassError)
							}
							theme="yellow"
							onClick={(e) => {
								e.stopPropagation()
								mutatePreJoinClass({ classId, is_role_mode })
							}}
						>
							{!crowded ? 'Быстрая запись' : '-'}
						</Button>
						{crowded && <div className="crowded">Запись закрыта</div>}
					</div>
				)}

				{session && joined && (
					<Button
						className={'schedule-class__leave-class__btn'}
						theme="red"
						isLoading={isLeaveClassLoading}
						onClick={(e) => {
							e.stopPropagation()
							mutateLeaveClass(classId)
						}}
					>
						Отменить запись
					</Button>
				)}
				<Button
					className="fade-in"
					onClick={() => {
						navigate(`/workout/${classId}`)
					}}
				>
					Подробнее
				</Button>
				<span className="schedule-class__free-leave-tip">
					{isFreeRemoveAvailable ? (
						<>
							<p>Бесплатная отмена</p>
							<p>до {freeRemoveDeadline.format('HH:mm, D MMM')}</p>
						</>
					) : (
						'Уже недоступна'
					)}
				</span>
				{isEndedClass && <div className="ended"></div>}
			</article>
			{isModalOpen && (
				<Modal closeButton onClose={handleCloseModal}>
					<h1 className="schedule-class__modal-title">Запись на тренировку</h1>
					<JoinClassInfo
						handleJoinClass={mutateJoinClass}
						rolesAvailable={roles_available}
						date={date}
						isJoinClassLoading={isJoinClassLoading}
						classId={classId}
					/>
				</Modal>
			)}
		</>
	)
}

export default ScheduleClass
