import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getUserInfo } from 'shared/api/account'
import { STORE_ACCESS_TOKEN_NAME, STORE_USER_DATA_NAME } from 'shared/constants'
import { createSession } from 'shared/lib/auth/sessions'

import { api } from '../../../constants/api'
import './Confirmation.scss'

function ConfirmationEmailPage() {
	const navigate = useNavigate()

	const [errors, setErrors] = useState('')
	const [successMsg, setSuccessMsg] = useState('')

	useEffect(() => {
		const accessToken = localStorage.getItem(STORE_ACCESS_TOKEN_NAME)
		if (accessToken) navigate('/')
	}, [navigate])

	function submitForm(event) {
		event.preventDefault() // предотвращаем стандартное поведение формы

		const form = event.target // получаем форму из события
		const formData = new FormData(form) // создаем объект FormData

		api
			.post('confirmation/', formData)
			.then(async (response) => {
				createSession(response.data)
				const user = await getUserInfo()
				localStorage.setItem(STORE_USER_DATA_NAME, JSON.stringify(user))
				if (response.status === 201) {
					setSuccessMsg('Почта успешно подтверждена')

					setTimeout(() => navigate('/schedule/'), 2000)
				}
			})
			.catch(({ response }) => {
				// Если есть ошибки валидации, отображаем их на странице
				if (response.status === 404) {
					setErrors('Неверный код')
				}
			})
	}

	// присваиваем функцию обработчика события submit формы
	const Form = () => {
		const errorActive = errors ? 'error_active' : ''
		const successActive = successMsg ? 'success_active' : ''

		return (
			<div className="confirmation-page">
				<div className="confirmation-page__content wrapper">
					<h1 className="confirmation-page__title">Регистрация</h1>
					<h2 className="confirmation-page__subtitle">Подтверждение почты</h2>
					<form className="confirmation-form" onSubmit={submitForm}>
						<label htmlFor="confirmation-email">
							На указанную почту было выслано письмо с кодом (если его нет во
							входящих, проверьте спам). Введите код из письма в строке ниже.
						</label>

						<input
							id="confirmation-email"
							type="text"
							name="confirmation_token"
							placeholder="Проверочный код из письма"
						/>

						<div className={`error ${errorActive} ${successActive}`}>
							{errors}
						</div>

						<button
							type="submit"
							className="confirmation-form__btn btn btn--bg"
						>
							Подтвердить
						</button>
					</form>
				</div>
			</div>
		)
	}

	return <Form />
}
export default ConfirmationEmailPage
