import clsx from 'clsx'
import dayjs from 'dayjs'
import {
	CSSProperties,
	FC,
	TouchEvent,
	useEffect,
	useRef,
	useState,
} from 'react'

import { useResize } from 'pages/SchedulePage/zScheduleCal/useResize'

import {
	JoinClassInfo,
	useJoinClassMutate,
	useJoinClassStore,
	useLeaveClassMutate,
	usePreJoinClassMutate,
} from 'features/joinClass'

import { CloseIcon } from 'shared/icons'
import { sortPlayers } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IRoles } from 'shared/types/classTypes'
import { Button } from 'shared/ui/button'
import { VolleyBallsBg } from 'shared/ui/volleyBallsBg'

import './workoutSignUp.scss'

interface IWorkoutSignUp {
	readonly classId: number
	readonly date: string
	readonly rolesAvailable: IRoles
	readonly players: number[]
	readonly limit: number
	readonly free_remove_to_class: number
}

export const WorkoutSignUp: FC<IWorkoutSignUp> = ({
	classId,
	players,
	date,
	rolesAvailable,
	limit,
	free_remove_to_class,
}) => {
	const { isLargeScreen } = useResize()
	const session = useSessionStore((state) => state.session)
	const [joined, setJoined] = useState(
		(session && players.includes(session.id)) ?? false
	)

	const defaultPlayersSquad = session
		? sortPlayers(session.id, players)
		: players
	const [playersSquad, setPlayersSquad] = useState(defaultPlayersSquad)
	const [playersCount, setPlayersCount] = useState(players.length)

	const {
		mutate: joinClassMutate,
		isPending: isJoinClassLoading,
		isError: isJoinClassError,
	} = useJoinClassMutate(
		classId,
		setPlayersCount,
		setJoined,
		handleClose,
		setPlayersSquad
	)
	const { mutate: leaveClassMutate, isPending: isLeaveClassLoading } =
		useLeaveClassMutate(
			classId,
			setPlayersCount,
			setJoined,
			undefined,
			setPlayersSquad
		)
	const {
		mutate: preJoinClassMutate,
		isPending: isPreJoinClassLoading,
		isError: isPreJoinClassError,
		isSuccess: isPreJoinClassSuccess,
	} = usePreJoinClassMutate(classId, date, undefined, joinClassMutate)

	useEffect(() => {
		if (session) {
			setPlayersSquad(sortPlayers(session.id, players))
			if (players.includes(session.id)) {
				setJoined(true)
			}
		} else {
			setJoined(false)
		}
	}, [session, players])

	const [isOpenOverlay, setIsOpenOverlay] = useState(false)
	const [touchPosition, setTouchPosition] = useState<number | null>(null)
	const ref = useRef<HTMLDivElement | null>(null)
	function handleClose() {
		// ref.current?.classList.add('workout-sign-up__overlay__out')
		setTimeout(() => {
			setIsOpenOverlay(false)
			resetStore()
			document.body.style.overflow = ''
			ref.current?.classList.remove('workout-sign-up-mob__overlay__out')
		}, 300)
	}
	function handleOpen() {
		if (!isLargeScreen) {
			setIsOpenOverlay(true)
			document.body.style.overflow = 'hidden'
		}
	}

	const {
		reset: resetStore,
		subscribesForClass,
		userSubscribes,
		errorMessage,
	} = useJoinClassStore(
		({ reset, subscribesForClass, userSubscribes, errorMessage }) => ({
			reset,
			subscribesForClass,
			userSubscribes,
			errorMessage,
		})
	)
	const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		const touchDown = e.touches[0].clientY
		const target = e.target as HTMLElement
		if (target.id === 'join-class-title') {
			setTouchPosition(touchDown)
		} else {
			setTouchPosition(null)
		}
	}

	const dataCount = [subscribesForClass, userSubscribes, rolesAvailable].filter(
		Boolean
	).length

	const handleJoin = () => {
		preJoinClassMutate({ classId, is_role_mode: !!rolesAvailable })
	}
	const freeRemoveDeadline = dayjs(date).subtract(free_remove_to_class, 'hour')
	const isFreeRemoveAvailable = dayjs().isBefore(freeRemoveDeadline)
	const renderSquad = () => {
		return (
			<div className="workout-sign-up__squad">
				<p className="workout-sign-up__squad__title">Состав: </p>
				<ol type="1" className="workout-sign-up__squad__list">
					{[...playersSquad].map((player, idx) => (
						<li className={'workout-sign-up__squad__list__item'} key={player}>
							<span
								className={clsx(player === session?.id && 'green-highlight')}
							>{`${
								idx + 1
							}. Диагональный: ${player} + Ну очень Длинное имя игрока`}</span>
						</li>
					))}
				</ol>
			</div>
		)
	}
	const renderSignUp = () => (
		<article
			ref={ref}
			className={clsx(
				'workout-sign-up'
				// !isLargeScreen && isOpenOverlay && 'workout-sign-up__overlay-active'
			)}
		>
			<div className="workout-sign-up__header">
				<div>
					<p className="workout-sign-up__header__title">
						Занято {playersCount} из {limit}
					</p>
					<span className="workout-sign-up__header__free-leave-tip">
						{isFreeRemoveAvailable
							? `Бесплатная отмена до ${freeRemoveDeadline.format(
									'HH:mm, D MMM'
							  )}`
							: 'Уже недоступна'}
					</span>
				</div>
			</div>
			<div
				onClick={(e) => {
					if (e.currentTarget.ariaLabel !== 'Закрыть окно') {
						e.stopPropagation()
					}
				}}
				className="workout-sign-up__wrapper"
			>
				{userSubscribes || errorMessage || subscribesForClass || !session ? (
					<JoinClassInfo
						classId={classId}
						date={date}
						rolesAvailable={rolesAvailable}
						handleJoinClass={joinClassMutate}
						isJoinClassLoading={isJoinClassLoading}
						className="workout-sign-up__content"
					/>
				) : (
					renderSquad()
				)}
				{!isLargeScreen && false && (
					<button
						onClick={handleClose}
						aria-label="Закрыть окно"
						className="workout-sign-up__wrapper__close-btn"
					>
						<CloseIcon />
					</button>
				)}
			</div>
			{!userSubscribes && !errorMessage && !subscribesForClass && session && (
				<div className="workout-sign-up__footer">
					{joined ? (
						<Button
							isLoading={isLeaveClassLoading}
							onClick={() => leaveClassMutate(classId)}
							className="workout-sign-up__footer__button"
							theme="red"
						>
							Отменить запись
						</Button>
					) : userSubscribes ||
					  errorMessage ||
					  subscribesForClass ||
					  !session ? null : (
						<Button
							isLoading={isPreJoinClassLoading || isJoinClassLoading}
							className="workout-sign-up__footer__button"
							onClick={handleJoin}
						>
							Записаться
						</Button>
					)}
				</div>
			)}
		</article>
	)
	return renderSignUp()
	// return isLargeScreen ? (
	// 	renderSignUp()
	// ) : (
	// 	<>
	// 		<div className="workout-sign-up-mob">
	// 			<div className="workout-sign-up-mob__wrapper">
	// 				{joined ? (
	// 					<Button
	// 						isLoading={isLeaveClassLoading}
	// 						onClick={() => leaveClassMutate(classId)}
	// 						theme="red"
	// 					>
	// 						Отменить запись
	// 					</Button>
	// 				) : (
	// 					<Button
	// 						isLoading={isPreJoinClassLoading || isJoinClassLoading}
	// 						className="workout-sign-up-mob__button"
	// 						onClick={handleJoin}
	// 					>
	// 						Записаться
	// 					</Button>
	// 				)}
	// 			</div>
	// 		</div>
	// 		{isOpenOverlay && !isPreJoinClassLoading && (
	// 			<div onClick={handleClose} className="workout-sign-up-mob__overlay">
	// 				{renderSignUp()}
	// 			</div>
	// 		)}
	// 	</>
	// )
}
