import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { ALL_LEVELS, LIGHT_LEVEL } from 'shared/constants/levels'
import { Interval } from 'shared/ui/interval'
import { LevelInfo } from 'shared/ui/level'

import { useScheduleStore } from '../../schedule-store'
import { getDayClasses } from '../getDayClasses'
import { getMonth } from '../utils'
import './month.scss'
import MonthDay from './monthDay/MonthDay'
import { MonthWeekDays } from './monthWeekDays/monthWeekDays'

dayjs.extend(isoWeek)
dayjs.locale('ru')
interface IMonth {
	currentStartRange: dayjs.Dayjs
}
const Month: FC<IMonth> = ({ currentStartRange }) => {
	const { classes, setScheduleView } = useScheduleStore(
		({ classes, setScheduleView }) => ({
			classes,
			setScheduleView,
		})
	)
	const month = getMonth(currentStartRange)
	const selectedDayClasses = getDayClasses(classes, currentStartRange) ?? []
	return (
		<div className="month-schedule">
			<div className="month-schedule__wrapper">
				<MonthWeekDays />
				<div className="month-view">
					{month.map((row, i) => (
						<div className="month-week" key={i}>
							{row.map((day) => {
								return (
									<MonthDay
										key={day.format('YYYY-MM-DD')}
										day={day}
										currentStartRange={currentStartRange}
									/>
								)
							})}
						</div>
					))}
				</div>
			</div>
			<div id="small-class-container" className="selected-day-classes">
				<div className="selected-day-classes__date">
					<span>{currentStartRange.format('dddd, D MMM')}</span>
				</div>
				{selectedDayClasses.map(
					({ date, duration, id, level, is_changed_level }) => {
						return (
							<Link
								to={`/workout/${id}`}
								key={id + date}
								className="selected-day-classes__class"
							>
								<div className="selected-day-classes__class__about">
									<LevelInfo
										className="selected-day-classes__class__about__level"
										levelTitle={level.title}
										levelColor={level.color}
										is_changed_level={is_changed_level}
									/>
									<Interval start={date} duration={duration} />
								</div>
								<svg
									width="11"
									height="18"
									viewBox="0 0 11 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.25 0.75L9.01875 8.51875C9.14472 8.64726 9.21528 8.82004 9.21528 9C9.21528 9.17996 9.14472 9.35274 9.01875 9.48125L1.25 17.25"
										stroke="white"
										stroke-width="2"
									/>
								</svg>
							</Link>
						)
					}
				)}
			</div>
		</div>
	)
}

export default Month
