import { FC } from 'react';

interface ISharedIcon {
  readonly className?: string;
}

export const SharedIconBlack: FC<ISharedIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="25" fill="black" />
      <path
        d="M30.2461 25.5537L29.1367 24.4443L33.2773 20.3115L29.1367 16.1787L30.2461 15.0693L34.3789 19.21C34.6699 19.5027 34.8333 19.8987 34.8333 20.3115C34.8333 20.7243 34.6699 21.1203 34.3789 21.4131L30.2461 25.5537Z"
        fill="white"
      />
      <path
        d="M25.0039 27.3428H23.4414V23.4365C23.4414 22.4005 23.853 21.407 24.5855 20.6744C25.3181 19.9418 26.3117 19.5303 27.3477 19.5303H33.5977V21.0928H27.3477C26.7261 21.0928 26.1299 21.3397 25.6904 21.7792C25.2508 22.2188 25.0039 22.8149 25.0039 23.4365V27.3428Z"
        fill="white"
      />
      <path
        d="M31.2539 35.1553H17.1914C16.5698 35.1553 15.9737 34.9083 15.5341 34.4688C15.0946 34.0293 14.8477 33.4331 14.8477 32.8115V18.749C14.8477 18.1274 15.0946 17.5313 15.5341 17.0917C15.9737 16.6522 16.5698 16.4053 17.1914 16.4053H25.0039V17.9678H17.1914C16.9842 17.9678 16.7855 18.0501 16.639 18.1966C16.4925 18.3431 16.4102 18.5418 16.4102 18.749V32.8115C16.4102 33.0187 16.4925 33.2174 16.639 33.364C16.7855 33.5105 16.9842 33.5928 17.1914 33.5928H31.2539C31.4611 33.5928 31.6598 33.5105 31.8063 33.364C31.9528 33.2174 32.0352 33.0187 32.0352 32.8115V27.3428H33.5977V32.8115C33.5977 33.4331 33.3507 34.0293 32.9112 34.4688C32.4716 34.9083 31.8755 35.1553 31.2539 35.1553Z"
        fill="white"
      />
    </svg>
  );
};
