import clsx from 'clsx'
import { FC } from 'react'

import { TimeIcon } from 'shared/icons'
import { getClassTime } from 'shared/lib/getClassTime'

import './interval.scss'

interface IInterval {
	readonly start: string
	readonly duration: number
	readonly withIcon?: boolean
	readonly className?: string
}

export const Interval: FC<IInterval> = ({
	start,
	duration,
	className,
	withIcon = true,
}) => {
	const interval = getClassTime(start, duration)
	return (
		<div className={clsx('interval', className)}>
			{withIcon && <TimeIcon />}
			<span>{interval}</span>
		</div>
	)
}
