import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useResize } from 'pages/SchedulePage/zScheduleCal/useResize'

import {
	ArrowBlackBg,
	CalendarIcon,
	FavoriteIconBlack,
	LocationIconBlack,
	SharedIconBlack,
	SuccessIcon,
} from 'shared/icons'
import { IconTitle } from 'shared/ui/iconTitle'
import { Interval } from 'shared/ui/interval'

import './workoutHeader.scss'

interface IWorkoutHeader {
	readonly date: string
	readonly is_changed_date: boolean
	readonly duration: number
}

export const WorkoutHeader: FC<IWorkoutHeader> = ({
	date,
	duration,
	is_changed_date,
}) => {
	const navigate = useNavigate()
	const [isLinkCopied, setIsLinkCopied] = useState(false)
	const { isTabletScreen } = useResize()
	return (
		<article className="workout-header">
			<div className="workout-header__back">
				<button onClick={() => navigate('/schedule')}>
					<ArrowBlackBg className="workout-header__back__icon" />
				</button>
				<span>К расписанию</span>
			</div>
			{!isTabletScreen && (
				<div className="workout-header__date">
					<IconTitle
						Icon={CalendarIcon}
						title={dayjs(date).format('DD MMMM, dddd')}
						className="workout-header__date__data"
					/>
					<Interval
						className={clsx(
							'workout-header__date__interval',
							is_changed_date && 'red-highlight',
							'bold'
						)}
						start={date}
						duration={duration}
						withIcon
					/>
				</div>
			)}
			<div className="workout-header__actions">
				<button
					onClick={() => {
						navigator.clipboard.writeText(window.location.href)
						setIsLinkCopied(true)
					}}
					className="workout-header__actions__share"
				>
					<SharedIconBlack className="workout-header__actions__icon" />
					<span className="workout-header__actions__share__tooltip">
						{isLinkCopied ? (
							<span className="workout-header__actions__share__tooltip__success">
								Скопировано
								<SuccessIcon className="workout-header__actions__share__tooltip__success__icon" />
							</span>
						) : (
							!isTabletScreen && 'Скопировать ссылку на тренировку'
						)}
					</span>
				</button>
				<button>
					<LocationIconBlack className="workout-header__actions__icon" />
				</button>
				<button>
					<FavoriteIconBlack className="workout-header__actions__icon" />
				</button>
			</div>
		</article>
	)
}
