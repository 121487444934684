import { useState } from 'react'
// Components
// Context
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { login } from 'shared/api/account'
import { getUserInfo } from 'shared/api/account'
import { IAuthFields } from 'shared/api/account/types'
import { STORE_USER_DATA_NAME } from 'shared/constants'
import { EyeIcon } from 'shared/icons'
import { clearSession, createSession } from 'shared/lib/auth/sessions'
import { handleErrorStatus, toastError } from 'shared/lib/handleError'
import { useSessionStore } from 'shared/model'
import { Button } from 'shared/ui/button'
import { Logo } from 'shared/ui/logo'
import { Modal } from 'shared/ui/modal'

// Files
import './PopupAccount.scss'

export const PopupAccount = () => {
	const navigate = useNavigate()

	const { isPopupAccountOpen, setIsPopupAccountOpen } = useSessionStore(
		({ isPopupAccountOpen, setIsPopupAccountOpen }) => ({
			isPopupAccountOpen,
			setIsPopupAccountOpen,
		})
	)
	/*** Handlers ***/
	const handleClosePopup = () => setIsPopupAccountOpen(!isPopupAccountOpen)
	const [isPasswordShown, setIsPasswordShown] = useState(false)
	const { session, setSession } = useSessionStore(
		({ session, setSession }) => ({ session, setSession })
	)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IAuthFields>()
	const onSubmit = async (values: IAuthFields) => {
		try {
			const tokens = await login(values)
			createSession(tokens)
			setIsPopupAccountOpen(!isPopupAccountOpen)
			const user = await getUserInfo()
			localStorage.setItem(STORE_USER_DATA_NAME, JSON.stringify(user))
			setSession(user)
		} catch (e) {
			if (handleErrorStatus(e) === 401) {
				toast.error('Неверный логин или пароль, попробуйте снова')
			} else {
				toastError(e)
			}
		}
	}

	const handleLogout = () => {
		clearSession()

		setIsPopupAccountOpen(!isPopupAccountOpen)
		setSession(null)
	}

	return (
		<Modal id="popup-account-modal" onClose={handleClosePopup} closeButton>
			<div className="popup-account">
				<Logo className="popup-account__logo" />
				{!session ? (
					<>
						<form
							className="popup-account__form"
							onSubmit={handleSubmit(onSubmit)}
							autoComplete="on"
						>
							<div className="popup-account__form__control">
								<div className="popup-account__form__control__wrapper">
									<input
										required
										type="text"
										placeholder="Логин"
										id="username"
										autoFocus
										aria-invalid={errors.username ? 'true' : 'false'}
										className="popup-account__form__control__field"
										{...register('username', { required: true })}
									/>
									<label
										htmlFor="username"
										className="popup-account__form__control__label"
									>
										Логин
										<span>*</span>
									</label>
								</div>
								{errors.username?.type === 'required' && (
									<div className="popup-account__form__control__error">
										Введите логин
									</div>
								)}
							</div>
							<div className="popup-account__form__control">
								<div className="popup-account__form__control__wrapper">
									<input
										required
										type={isPasswordShown ? 'text' : 'password'}
										id="password"
										placeholder="Пароль"
										aria-invalid={errors.password ? 'true' : 'false'}
										className="popup-account__form__control__field"
										{...register('password', { required: true })}
									/>
									<label
										className="popup-account__form__control__label"
										htmlFor="password"
									>
										Пароль
										<span>*</span>
									</label>
									<button
										type="button"
										className="popup-account__form__control__eye"
										aria-label={
											isPasswordShown ? 'Скрыть пароль' : 'Показать пароль'
										}
										onClick={() => setIsPasswordShown(!isPasswordShown)}
									>
										<EyeIcon shown={isPasswordShown} />
									</button>
								</div>
								{errors.password?.type === 'required' && (
									<div className="popup-account__form__control__error">
										Введите пароль
									</div>
								)}
							</div>
							<Link
								onClick={handleClosePopup}
								className="popup-account__form__forgot-password"
								to="/recovery-password-email"
							>
								Забыли пароль?
							</Link>
							<div className="popup-account__form__buttons">
								<Button theme="green" type="submit">
									Вход
								</Button>
								<Button
									onClick={() => {
										handleClosePopup()
										navigate('/registration')
									}}
								>
									Регистрация
								</Button>
							</div>
						</form>
					</>
				) : (
					<div className="popup-account__logout">
						<p>Выйти из аккаунта?</p>
						<Button theme="green" onClick={handleLogout}>
							Выход
						</Button>
					</div>
				)}
			</div>
		</Modal>
	)
}
