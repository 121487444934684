export const LIGHT_LEVEL = {
	title: 'Начальный',
	getColors: (color: string) => [color, '#fff', '#fff'],
}

export const MEDIUM_LEVEL = {
	title: 'Средний',
	getColors: (color: string) => [color, color, '#fff'],
}

export const HARD_LEVEL = {
	title: 'Сильный',
	getColors: (color: string) => [color, color, color],
}
export const TEAM_LEVEL = {
	title: 'Командный',
	getColors: (color: string) => [color, color, color],
}

export const USER_LEVELS = [LIGHT_LEVEL, MEDIUM_LEVEL, HARD_LEVEL]

export const ALL_LEVELS = [LIGHT_LEVEL, MEDIUM_LEVEL, HARD_LEVEL, TEAM_LEVEL]
