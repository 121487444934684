export const pluralAge = (age: number) => {
	const ruCardinalRules = new Intl.PluralRules('ru-RU', { type: 'cardinal' })
	const suffixes = new Map([
		['one', 'год'],
		['few', 'года'],
		['many', 'лет'],
	])
	const rule = ruCardinalRules.select(age)
	return `${age} ${suffixes.get(rule)}`
}
