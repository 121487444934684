import { FC, MouseEventHandler } from 'react';
import './toggleButton.scss';
import clsx from 'clsx';
import { IconType } from 'react-icons';
interface IToggleButton {
  readonly children: string;
  readonly active: boolean;
  readonly className?: string;
  readonly Icon?: IconType;
  readonly CloseIcon?: IconType;
  readonly handleCloseIcon?: MouseEventHandler<HTMLDivElement> | undefined;
  /** The function is executed when the button is pressed. */
  readonly onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ToggleButton: FC<IToggleButton> = ({
  children,
  active,
  className,
  onClick,
  Icon,
  CloseIcon,
  handleCloseIcon,
}) => {
  return (
    <button onClick={onClick} className={clsx('toggle-button', active && 'active', className)}>
      {Icon && <Icon className="toggle-button__icon" />}
      {children}
      {CloseIcon && (
        <div onClick={handleCloseIcon} className={clsx('toggle-button__icon', 'toggle-button__icon--close')}>
          <CloseIcon />
        </div>
      )}
    </button>
  );
};
