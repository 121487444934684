import { FC } from 'react';

interface ISuccessIcon {
  readonly className?: string;
}

export const SuccessIcon: FC<ISuccessIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.85156 9.16667L7.41807 16.557C8.1101 17.4758 9.51654 17.386 10.0861 16.3867L18.0016 2.5"
        stroke="url(#paint0_linear_3450_5483)"
        strokeWidth="3.2"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3450_5483"
          x1="1.85156"
          y1="10.5"
          x2="18.0016"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#72BF44" />
          <stop offset="1" stopColor="#FAF62D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
