import {
	UseMutateFunction,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query'
import dayjs from 'dayjs'

import {
	IJoinClass,
	joinClass,
	leaveClass,
	preJoinClass,
} from 'shared/api/class'
import { getPayUrl } from 'shared/api/pay'
import { getProductsForClass } from 'shared/api/product'
import { clearSession } from 'shared/lib/auth/sessions'
import {
	handleErrorMessage,
	handleErrorStatus,
	toastError,
} from 'shared/lib/handleError'
import { isSubscribeAvailableForClass } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IRoles } from 'shared/types/classTypes'

import { useJoinClassStore } from '../model/join-class-store'

export const usePreJoinClassMutate = (
	classId: number,
	classDate: string,
	onModalOpen?: () => void,
	mutateJoinClass?: UseMutateFunction<
		IJoinClass,
		Error,
		{
			subscribeId: number
			role?: keyof IRoles
		},
		unknown
	>
) => {
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	const {
		setSubscribesForClass,
		setUserSubscribes,
		setErrorMessage,
		setJoinStep,
	} = useJoinClassStore(
		({
			setErrorMessage,
			setSubscribesForClass,
			setUserSubscribes,
			setJoinStep,
		}) => ({
			setErrorMessage,
			setSubscribesForClass,
			setUserSubscribes,
			setJoinStep,
		})
	)
	return useMutation({
		mutationKey: ['preJoinClass', classId, dayjs().format('HH:mm')],
		mutationFn: (variables: {
			classId: number
			is_role_mode: boolean
			delayed_join?: boolean
			role?: keyof IRoles
		}) => preJoinClass(variables.classId),
		onSuccess: async (data, { classId, delayed_join, role, is_role_mode }) => {
			const availableSubscribes = data.filter(
				(sub) =>
					!sub.freeze && isSubscribeAvailableForClass(classDate, sub.date_end)
			)
			if (is_role_mode) {
				setJoinStep('chooseRole')
			}
			const hasAvailableSubscribe = availableSubscribes.length > 0
			setUserSubscribes(data)
			if (delayed_join && mutateJoinClass) {
				const subscribeId = data[data.length - 1].id
				mutateJoinClass({ subscribeId, role })
				return
			}
			if (
				availableSubscribes.length === 1 &&
				!is_role_mode &&
				mutateJoinClass
			) {
				const [subscribe] = availableSubscribes
				mutateJoinClass({ subscribeId: subscribe.id, role })
				return
			}

			if (!hasAvailableSubscribe) {
				const subscribesForClass = await getProductsForClass(classId)
				if (subscribesForClass) {
					setSubscribesForClass(subscribesForClass)
				}
			}
			if (onModalOpen) onModalOpen()
		},
		onError: async (error) => {
			if (handleErrorStatus(error) === 401) {
				clearSession()
				setIsPopupAccountOpen(true)
				return
			}
			/** TODO: Если абонементы отсутствуют */
			if (
				handleErrorMessage(error) ===
				'Для записи на тренировку купите подходящий абонемент.'
			) {
				const availableSubscribes = await getProductsForClass(classId)
				if (availableSubscribes) {
					setSubscribesForClass(availableSubscribes)
					if (onModalOpen) onModalOpen()
					return
				}
			}
			/** TODO: Ошибки записи в неподходящую группу */
			if (handleErrorStatus(error) === 403) {
				setErrorMessage(handleErrorMessage(error))
				if (onModalOpen) onModalOpen()
				return
			}
			toastError(error, 'Не удалось записаться на тренировку')
		},
	})
}

export const useJoinClassMutate = (
	classId: number,
	setPlayersCount: (value: React.SetStateAction<number>) => void,
	setJoined: (value: React.SetStateAction<boolean>) => void,
	onCloseModal?: () => void,
	setPlayers?: React.Dispatch<React.SetStateAction<number[]>>
) => {
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	const session = useSessionStore((state) => state.session)
	return useMutation({
		mutationKey: ['joinClass', classId],
		mutationFn: (variables: { subscribeId: number; role?: keyof IRoles }) =>
			joinClass(classId, variables.subscribeId, variables.role),
		onSuccess: (data) => {
			setPlayersCount(data.players_count)
			setJoined(true)
			if (setPlayers && session) {
				setPlayers((prev) => [session.id, ...prev])
			}
			// toast.success('Вы успешно записаны на тренировку', {
			//   classNames: {
			//     actionButton: 'toast-action-button',
			//   },
			//   action: {
			//     label: 'Подробнее',
			//     onClick: () => navigate(`/class/${classId}`),
			//   },
			//   duration: 2000,
			// });
			if (onCloseModal) {
				onCloseModal()
			}
		},
		onError: (error) => {
			if (handleErrorStatus(error) === 401) {
				clearSession()
				setIsPopupAccountOpen(true)
				return
			}
			toastError(error)
		},
	})
}

export const useRedirectToPayMutate = (
	classId: number,
	role: keyof IRoles | null
) => {
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	return useMutation({
		mutationKey: ['redirectToPay', classId],
		mutationFn: (productId: number) => getPayUrl(productId),
		onSuccess: (data) => {
			const delayed_data = {
				classId,
				role,
			}
			sessionStorage.setItem(
				'delayed_appointment',
				JSON.stringify(delayed_data)
			)
			window.location.href = data.url
		},
		onError: (error) => {
			if (handleErrorStatus(error) === 401) {
				setIsPopupAccountOpen(true)
				return
			}
			toastError(error)
		},
	})
}

export const useLeaveClassMutate = (
	classId: number,
	setPlayersCount: (value: React.SetStateAction<number>) => void,
	setJoined: (value: React.SetStateAction<boolean>) => void,
	setIsModalOpen?: (value: React.SetStateAction<boolean>) => void,
	setPlayers?: React.Dispatch<React.SetStateAction<number[]>>
) => {
	const setErrorMessage = useJoinClassStore((state) => state.setErrorMessage)
	const setIsPopupAccountOpen = useSessionStore(
		(state) => state.setIsPopupAccountOpen
	)
	const session = useSessionStore((state) => state.session)
	return useMutation({
		mutationKey: ['leave_class', classId],
		mutationFn: leaveClass,
		onSuccess: (data) => {
			if (!data.free) {
				setErrorMessage(data.message)
				if (setIsModalOpen) setIsModalOpen(true)
			}
			if (setPlayers && session) {
				setPlayers((prev) => prev.filter((id) => id !== session.id))
			}
			setPlayersCount(data.players_count)
			setJoined(false)
		},
		onError: (error) => {
			toastError(error)
			// Logout user
			if (handleErrorStatus(error) === 401) {
				clearSession()
				setJoined(false)
				setIsPopupAccountOpen(true) // Open login popup
			}
		},
	})
}
