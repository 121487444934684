import { FC } from 'react'

import { ProfileCard } from 'entities/profileCard'
import { Progress } from 'entities/progress'

import { IUserData } from 'shared/api/account'
import { ABILITIES } from 'shared/constants'
import { ALL_LEVELS } from 'shared/constants/levels'
import { pluralAge } from 'shared/lib/utils'
import { IClassLevel } from 'shared/types'
import { LevelInfo } from 'shared/ui/level'

import './gameData.scss'

interface IGameData {
	readonly data: IUserData
}

export const GameData: FC<IGameData> = ({ data }) => {
	return (
		<article className="game-data">
			<ProfileCard>
				<h3 className="game-data__title">Игровые данные</h3>
				<ul className="game-data__content">
					<li className="game-data__content__item">
						<span>Уровень</span>
						<LevelInfo
							levelTitle={data.level_id.title}
							levelColor={'aqua'}
							is_changed_level={false}
						/>
					</li>
					<li className="game-data__content__item">
						<span>Амплуа</span>
						<span>
							{data.role ? data.role.map(({ name }) => name).join(', ') : '-'}
						</span>
					</li>
					<li className="game-data__content__item">
						<span>Команда</span>
						<span>
							{data.team.length
								? data.team.map(({ title }) => title).join(', ')
								: '-'}
						</span>
					</li>
					<li className="game-data__content__item">
						<span>Игровой опыт</span>
						<span>{data.experience ? pluralAge(data.experience) : '-'}</span>
					</li>
				</ul>
				<ul className="game-data__abilities">
					{ABILITIES.map(({ ability, title }) => (
						<li className="game-data__abilities__item" key={ability}>
							<Progress
								title={title}
								value={data[ability]}
								maxValue={9}
								key={ability}
							/>
						</li>
					))}
				</ul>
			</ProfileCard>
		</article>
	)
}
