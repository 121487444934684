export const ROLES = [
	{
		id: 1,
		name: 'Либеро',
	},
	{
		id: 2,
		name: 'Центральный блокирующий',
	},
	{
		id: 3,
		name: 'Диагональный',
	},
	{
		id: 4,
		name: 'Связующий',
	},
	{
		id: 5,
		name: 'Доигровщик',
	},
]
