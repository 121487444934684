import { FC } from 'react';
import './profileCard.scss';
import clsx from 'clsx';
interface IProfileCard {
  readonly children: React.ReactNode;
  readonly className?: string;
}

export const ProfileCard: FC<IProfileCard> = ({ children, className }) => {
  return <div className={clsx('profile-card', className)}>{children}</div>;
};
