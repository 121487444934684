import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from 'sonner';
import './layout.scss';

interface ILayout {
  readonly children?: React.ReactNode;
}

export const Layout: FC<ILayout> = ({ children }) => {
  return (
    <div className="layout">
      {/* <img
        alt=""
        fetchpriority="high"
        width="874"
        height="522"
        decoding="async"
        className="hero-background"
        srcSet={BG}
        src={BG}
      /> */}
      <Header />

      <main className="main">{children ?? <Outlet />}</main>

      <Footer />
      <Toaster richColors position="bottom-center" visibleToasts={1} />
    </div>
  );
};
