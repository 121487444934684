import { FC } from 'react';
import { Link } from 'react-router-dom';

import logo from 'shared/assets/logo.png';

interface ILogo {
  readonly className?: string;
}

export const Logo: FC<ILogo> = ({ className }) => {
  return (
    <Link to="/" className={className}>
      <img className="image-contain" src={logo} alt="Логотип" title="logo" />
    </Link>
  );
};
