import dayjs from 'dayjs'
import { FC } from 'react'

import { isSubscribeAvailableForClass } from 'shared/lib/utils'
import { ISubscribe } from 'shared/types'
import { ModalCheckbox } from 'shared/ui/modalCheckbox'

import './joinClassInfo.scss'

interface IUserSubscribes {
	readonly subscribes: ISubscribe[]
	readonly date: string
	readonly choosenSubscribe: number | undefined
	readonly setChoosenSubscribe: (id: number) => void
}
export const UserSubscribes: FC<IUserSubscribes> = ({
	subscribes,
	date,
	choosenSubscribe,
	setChoosenSubscribe,
}) => {
	return (
		<>
			<p className="joinClassInfo-container__content__subtitle">
				Выберите абонемент, с которого будет произведена запись на тренировку
			</p>
			<ul className="joinClassInfo-container__content__list">
				{subscribes.map((subscribe) => (
					<li key={subscribe.id}>
						<ModalCheckbox
							active={
								subscribe.id === choosenSubscribe &&
								!subscribe.freeze &&
								isSubscribeAvailableForClass(date, subscribe.date_end)
							}
							freeze={subscribe.freeze}
							disabled={!isSubscribeAvailableForClass(date, subscribe.date_end)}
							onClick={() => setChoosenSubscribe(subscribe.id)}
						>
							<div className="joinClassInfo-container__content__list__info-subscribe">
								<span>
									{subscribe.date_end
										? `до ${dayjs(subscribe.date_end).format('DD.MM.YYYY')}`
										: 'не активирован'}
								</span>
								{isSubscribeAvailableForClass(date, subscribe.date_end) ? (
									<span>{`ост. ${subscribe.balance} тр.`}</span>
								) : (
									<span>Истекает до начала тренировки</span>
								)}
							</div>
						</ModalCheckbox>
					</li>
				))}
			</ul>
		</>
	)
}
