import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'

import { useResize } from 'pages/SchedulePage/zScheduleCal/useResize'

import { Slider } from 'widgets/slider'
import { WorkoutHeader } from 'widgets/workoutHeader'
import { WorkoutInfo } from 'widgets/workoutInfo'
import { WorkoutSignUp } from 'widgets/workoutSignUp'
import { WorkoutStructure } from 'widgets/workoutStructure'

import { useJoinClassStore } from 'features/joinClass'

import { getClassById } from 'shared/api/class'
import { CalendarIcon, CloseIcon } from 'shared/icons'
import { useSessionStore } from 'shared/model'
import { IconTitle } from 'shared/ui/iconTitle'
import { Interval } from 'shared/ui/interval'

import './workout.scss'

interface IWorkoutPage {}

export const Workout: FC<IWorkoutPage> = () => {
	const resetStore = useJoinClassStore(({ reset }) => reset)
	const session = useSessionStore((state) => state.session)
	const [isPrepareToJoin, setIsPrepareToJoin] = useState(false)
	const { isLargeScreen } = useResize()
	useEffect(() => {
		window.scrollTo(0, 0)
		document.title = 'Тренировка'
		return () => {
			resetStore()
		}
	}, [resetStore])

	useEffect(() => {
		if (!session) {
			resetStore()
		}
	}, [session, resetStore])

	const joinRef = useRef<HTMLDivElement>(null)
	const { id } = useParams() as { id: string }
	const classId = Number(id)
	const [isOverlayOpen, setIsOverlayOpen] = useState(false)
	const {
		data: workout,
		isLoading,
		isSuccess,
	} = useQuery({
		queryKey: ['workout', id],
		queryFn: () => getClassById(classId),
	})

	return (
		<>
			{isSuccess && !isLoading && (
				<div className="workout">
					<WorkoutHeader
						date={workout.date}
						is_changed_date={workout.is_changed_date}
						duration={workout.duration}
					/>
					<div className="workout__content">
						{!isLargeScreen && (
							<div className="workout__content__time">
								<IconTitle
									Icon={CalendarIcon}
									title={dayjs(workout.date).format('DD MMMM, dddd')}
									className="workout__content__time__date"
								/>
								<Interval
									className={clsx(
										'workout__content__time__interval',
										workout.is_changed_date && 'red-highlight'
									)}
									start={workout.date}
									duration={workout.duration}
									withIcon
								/>
							</div>
						)}
						<section className="workout__content__main">
							<article className="workout__content__main__slider">
								<Slider gym={workout.gym.title} autoPlay />
							</article>
							<WorkoutInfo
								workout={workout}
								setOverlayOpen={setIsOverlayOpen}
							/>
						</section>
						<section ref={joinRef} className="workout__content__secondary">
							<WorkoutSignUp
								classId={classId}
								date={workout.date}
								rolesAvailable={workout.roles_available}
								players={workout.players}
								limit={workout.limit}
								free_remove_to_class={workout.free_remove_to_klass}
							/>
						</section>
					</div>
				</div>
			)}
			{isLoading && (
				<div className="workout__loader">
					<PuffLoader color="var(--green)" />
				</div>
			)}
			{isOverlayOpen && (
				<div
					onClick={() => setIsOverlayOpen(false)}
					className="workout__overlay"
				>
					<div className="workout__overlay__content">
						<div
							onClick={(e) => e.stopPropagation()}
							className="workout__overlay__content__video"
						>
							<button
								className="workout__overlay__content__video__close"
								aria-label="Закрыть видео плеер"
								onClick={() => setIsOverlayOpen(false)}
							>
								<CloseIcon />
							</button>

							<iframe
								title="Пример тренировки"
								src="https://vk.com/video_ext.php?oid=-32408054&id=456243413"
								width="100%"
								height="100%"
								allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
								allowFullScreen
							></iframe>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
