import { FC } from 'react';

interface IFreezeIcon {
  readonly className?: string;
}

export const FreezeIcon: FC<IFreezeIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.36775 2.31445L12.5017 4.6649L15.6356 2.31445M22.6869 9.36579L20.3365 12.4997L22.6869 15.6336M15.6356 22.685L12.5017 20.3345L9.36775 22.685M2.31641 15.6336L4.66685 12.4997L2.31641 9.36579"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.5006 14.0665C13.366 14.0665 14.0675 13.365 14.0675 12.4996C14.0675 11.6342 13.366 10.9326 12.5006 10.9326C11.6351 10.9326 10.9336 11.6342 10.9336 12.4996C10.9336 13.365 11.6351 14.0665 12.5006 14.0665Z"
        fill="white"
        stroke="white"
      />
      <path d="M12.5 1.53125V23.4688" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M1.53125 12.5H23.4688" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M4.66406 4.66504L20.3337 20.3347" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M4.66406 20.3347L20.3337 4.66504" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
