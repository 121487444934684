import { UseMutateFunction } from '@tanstack/react-query'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import { useRedirectToPayMutate } from 'features/joinClass/api/joinClass'
import { useJoinClassStore } from 'features/joinClass/model/join-class-store'

import { IJoinClass } from 'shared/api/class'
import {
	countAvailableSubscribes,
	isSubscribeAvailableForClass,
} from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IRoles } from 'shared/types/classTypes'
import { Button } from 'shared/ui/button'

import { AvailableSubscribes } from './availableSubscribes'
import './joinClassInfo.scss'
import { RolesList } from './rolesList'
import { UserSubscribes } from './userSubscribes'

interface IJoinClassInfo {
	readonly rolesAvailable: IRoles | null
	readonly handleJoinClass: UseMutateFunction<
		IJoinClass,
		Error,
		{
			subscribeId: number
			role?: keyof IRoles
		},
		unknown
	>
	readonly date: string
	readonly isJoinClassLoading: boolean
	readonly classId: number
	readonly className?: string
}

export const JoinClassInfo: FC<IJoinClassInfo> = ({
	handleJoinClass,
	rolesAvailable,
	date,
	isJoinClassLoading,
	classId,
	className,
}) => {
	const {
		userSubscribes,
		errorMessage,
		subscribesForClass,
		setJoinStep,
		joinStep,
	} = useJoinClassStore(
		({
			userSubscribes,
			errorMessage,
			subscribesForClass,
			setJoinStep,
			joinStep,
		}) => ({
			userSubscribes,
			errorMessage,
			subscribesForClass,
			setJoinStep,
			joinStep,
		})
	)
	const defaultChoose = subscribesForClass && subscribesForClass[0].id
	const [choosenProduct, setChoosenProduct] = useState<number | null>(
		defaultChoose
	)
	const [choosenRole, setChoosenRole] = useState<keyof IRoles | null>(null)
	const defaultSubscribe = userSubscribes?.find(
		(sub) => !sub.freeze && isSubscribeAvailableForClass(date, sub.date_end)
	)?.id
	const [choosenSubscribe, setChoosenSubscribe] = useState(defaultSubscribe)
	const { mutate: redirectToPay, isPending: isRedirectToPayLoading } =
		useRedirectToPayMutate(classId, choosenRole)
	const { session, setIsPopupAccountOpen } = useSessionStore(
		({ session, setIsPopupAccountOpen }) => ({
			session,
			setIsPopupAccountOpen,
		})
	)
	const hasOneActiveSubscribe =
		countAvailableSubscribes(userSubscribes, date) === 1
	const handleClick = () => {
		if (!session) {
			setIsPopupAccountOpen(true)
			return
		}
		if (joinStep === 'chooseRole') {
			if (choosenSubscribe && choosenRole && hasOneActiveSubscribe) {
				handleJoinClass({ subscribeId: choosenSubscribe, role: choosenRole })
				return
			}
			setJoinStep('join')
		} else {
			if (subscribesForClass) {
				if (!choosenProduct) return
				redirectToPay(choosenProduct)
			}

			if (choosenSubscribe) {
				const role = choosenRole ? choosenRole : undefined
				handleJoinClass({ subscribeId: choosenSubscribe, role })
			}
		}
	}

	return (
		<div className={clsx('joinClassInfo-container fade-in', className)}>
			<div className="joinClassInfo-container__content">
				{userSubscribes && joinStep === 'join' && !errorMessage && (
					<UserSubscribes
						subscribes={userSubscribes}
						date={date}
						setChoosenSubscribe={setChoosenSubscribe}
						choosenSubscribe={choosenSubscribe}
					/>
				)}
				{subscribesForClass && joinStep === 'join' && !errorMessage && (
					<AvailableSubscribes
						products={subscribesForClass}
						setChoosenProduct={setChoosenProduct}
						choosenProduct={choosenProduct}
					/>
				)}
				{rolesAvailable &&
					joinStep === 'chooseRole' &&
					!errorMessage &&
					session && (
						<RolesList
							roles={rolesAvailable}
							setChoosenRole={setChoosenRole}
							choosenRole={choosenRole}
						/>
					)}
				{errorMessage && (
					<p>{`${errorMessage} Для большей информации обратитесь к администратору`}</p>
				)}
			</div>
			{errorMessage ? (
				<Link
					className="joinClassInfo-container__link"
					target="_blank"
					to="https://t.me/volleybox_official"
				>
					<Button>Задать вопрос</Button>
				</Link>
			) : (
				<Button
					className={clsx('joinClassInfo-container__button')}
					disabled={!choosenRole && !!rolesAvailable && !!session}
					isLoading={isJoinClassLoading || isRedirectToPayLoading}
					onClick={handleClick}
				>
					Записаться
				</Button>
			)}
		</div>
	)
}
