import { IUser } from 'shared/api/account';
import { STORE_USER_DATA_NAME } from 'shared/constants';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ISessionStore {
  session: IUser | null;
  setSession: (session: IUser | null) => void;
  isPopupAccountOpen: boolean;
  setIsPopupAccountOpen: (isPopupAccountOpen: boolean) => void;
}

const user = localStorage.getItem(STORE_USER_DATA_NAME);
export const useSessionStore = create<ISessionStore>()(
  persist(
    (set) => ({
      session: user ? JSON.parse(user) : null,
      setSession: (session) => set({ session }),
      isPopupAccountOpen: false,
      setIsPopupAccountOpen: (isPopupAccountOpen) => set({ isPopupAccountOpen }),
    }),
    { name: 'session', partialize: (state) => ({ session: state.session }) }
  )
);
