import { watch } from 'fs'
import { ChangeEvent, FC, useState } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form'
import { toast } from 'sonner'

import { ProfileCard } from 'entities/profileCard'

import { ISettingFields, IUserData } from 'shared/api/account'
import { Input } from 'shared/input'
import { handleErrorMessage, toastError } from 'shared/lib/handleError'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'

import './secondaryInfo.scss'

interface ISecondaryInfo {
	readonly avatar: string | undefined
	readonly control: Control<ISettingFields>
	readonly setValue: UseFormSetValue<ISettingFields>
	readonly errors: FieldErrors<ISettingFields>
}

export const SecondaryInfo: FC<ISecondaryInfo> = ({
	avatar,
	control,
	setValue,
	errors,
}) => {
	const [userAvatar, setUserAvatar] = useState(avatar)
	const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const file = e.target.files[0]

			const loadFile = async () =>
				await new Promise((resolve, reject) => {
					const reader = Object.assign(new FileReader(), {
						onload: () => resolve(reader.result),
						onerror: () => reject(reader.error),
					})
					reader.readAsDataURL(file)
				})
			try {
				const result = (await loadFile()) as string
				setValue('avatar', result)
				setUserAvatar(result)
			} catch (error) {
				toastError(error)
			}
		}
	}
	return (
		<article className="secondary-info">
			<ProfileCard>
				<img
					className="secondary-info__avatar"
					src={userAvatar}
					alt="Аватар игрока"
				/>
				<p className="secondary-info__avatar-label">
					{errors.avatar ? (
						<span className="red-highlight">{errors.avatar.message}</span>
					) : (
						<>
							Фото <span className="green-highlight">*</span>
						</>
					)}
				</p>
				<Controller
					name="avatar"
					control={control}
					render={({ field }) => (
						<div className="secondary-info__control">
							<label
								htmlFor="avatar"
								className="secondary-info__control__change-button"
							>
								Изменить
							</label>
							<input
								className="secondary-info__control__input-file"
								type="file"
								{...field}
								onChange={uploadFile}
								id="avatar"
								value={''}
								accept="image/*"
							/>
						</div>
					)}
				/>

				<Controller
					name="about"
					control={control}
					render={({ field }) => (
						<div className="secondary-info__control">
							<label>О себе</label>
							<textarea
								placeholder="Расскажите о себе"
								className="secondary-info__control__about"
								{...field}
							/>
						</div>
					)}
				/>
			</ProfileCard>
		</article>
	)
}
