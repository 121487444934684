import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const PaymentSuccessPage = () => {
  const backToMainStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 45,
    marginRight: 50,
    maxWidth: 250,
    fontFamily: '"Docker", "Helvetica", "Arial", sans-serif',
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem('delayed_appointment')) {
      navigate('/schedule', { state: { from: 'payment-success' } });
    }
  });

  return (
    <div className="container" style={{ minHeight: 'calc(100vh - 523px)' }}>
      <h1 className="page-title page-title--confirm-token">Оплата успешно осуществлена</h1>

      <Link className="btn btn--bg" to="/" style={{ ...backToMainStyle }}>
        На главную
      </Link>

      <Link className="btn btn--bg" to="/schedule/" style={{ ...backToMainStyle }}>
        К расписанию
      </Link>
    </div>
  );
};

export default PaymentSuccessPage;
