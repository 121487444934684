// Context
import clsx from 'clsx'
import DropDownIcon from 'components/IconComponents/DropDownIcon'
import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useResize } from 'pages/SchedulePage/zScheduleCal/useResize'

import { PopupAccount } from 'widgets/PopupAccount'

import { HEADER_LINKS } from 'shared/constants'
import { useSessionStore } from 'shared/model'
import { HamburgerButton } from 'shared/ui/hamburgerButton'
import { Logo } from 'shared/ui/logo'

// Files
import { api } from '../../constants/api'
// Components
import InformerAbonementIcon from '../IconComponents/InformerAbonementIcon'
import LogoutIcon from '../IconComponents/LogoutIcon'
import UserIcon from '../IconComponents/UserIcon'
import Social from '../Social/Social'
import './Header.scss'

const Header = () => {
	const { session, isPopupAccountOpen, setIsPopupAccountOpen } =
		useSessionStore(
			({ session, setIsPopupAccountOpen, isPopupAccountOpen }) => ({
				session,
				setIsPopupAccountOpen,
				isPopupAccountOpen,
			})
		)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [hasAbonement, setHasAbonement] = useState(false)

	const navRef = useRef<HTMLElement>(null)
	const triggerRef = useRef<HTMLButtonElement>(null)

	const navigate = useNavigate()
	const accessToken = localStorage.getItem('access_token')
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				navRef.current &&
				!navRef.current.contains(event.target as Node) &&
				triggerRef.current &&
				!triggerRef.current.contains(event.target as Node) &&
				isMenuOpen
			) {
				setIsMenuOpen(false)
			}
		}
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [navRef, isMenuOpen, setIsMenuOpen, triggerRef])

	useEffect(() => {
		if (session) {
			api.get('has-available-balance/').then(({ data }) => {
				if (data.available_balance) {
					setHasAbonement(true)
				}
			})
		}
	}, [session])

	/*** Handlers ***/
	const handleLogin = () => {
		accessToken ? navigate('/') : setIsPopupAccountOpen(!isPopupAccountOpen)
	}

	// попап выхода из аккаунта
	const handleLogout = () => {
		setIsPopupAccountOpen(!isPopupAccountOpen)
	}
	const { isMobileScreen, isXLargeScreen } = useResize()

	return (
		<>
			<header className="header" id="header">
				<div className="header__content">
					{/*** Logo ***/}
					<Logo className="header__logo" />
					{/*** Menu ***/}
					{isXLargeScreen ? (
						<nav className="header__nav">
							<ul className="header__list">
								<li className="header__item header__item--dropdown">
									Тренировки
									<DropDownIcon className="header__item__dropdown__icon" />
									<ul className="header__item__dropdown">
										<li className="header__item__dropdown__item">
											<Link to={'/training/'}>Виды тренировок</Link>
										</li>
										<li className="header__item__dropdown__item">
											<Link to={'/gym/'}>Залы</Link>
										</li>
									</ul>
								</li>
								<li className="header__item">
									<Link to={'/schedule/'}>Расписание</Link>
								</li>
								<li className="header__item">
									<Link to={'/payment/'}>Цены</Link>
								</li>
							</ul>
						</nav>
					) : (
						<nav
							ref={navRef}
							className={clsx(
								'header__nav-mob',
								isMenuOpen && 'header__nav-mob--open'
							)}
						>
							<ul className="header__nav-mob__list">
								{HEADER_LINKS.map(({ path, title }) => (
									<li
										key={path}
										onClick={() => setIsMenuOpen(false)}
										className="header__nav-mob__item"
									>
										<Link className="header__nav-mob__link" to={path}>
											{title}
										</Link>
									</li>
								))}
							</ul>
						</nav>
					)}

					<div className="header__icons">
						{/*** User abonement informer ***/}
						{session && isXLargeScreen && (
							<Link
								to={hasAbonement ? '#' : '/payment/'}
								className={`ab-informer ${
									hasAbonement ? 'is-abonement' : 'no-abonement'
								}`}
							>
								<InformerAbonementIcon
									active={hasAbonement}
									fill={hasAbonement ? 'white' : '#7E7E7E'}
									className="ab-informer__icon"
								/>

								<span className="ab-informer__text">
									{hasAbonement ? 'Абонемент в наличии' : 'Купить абонемент'}
								</span>
							</Link>
						)}
						{!isMobileScreen && <Social className={'header__icon'} />}
						{/*** Login/Account ***/}
						{!session ? (
							<button
								className="header__icon header__icon--login"
								onClick={() => handleLogin()}
							>
								<UserIcon />
							</button>
						) : (
							<Link
								to="/profile/about"
								className="header__icon header__icon--account"
							>
								<UserIcon />
							</Link>
						)}
						{/*** Logout ***/}
						{session && (
							<button
								className="header__icon header__icon--logout"
								onClick={() => handleLogout()}
							>
								<LogoutIcon />
							</button>
						)}

						{/*** Burger ***/}
						{!isXLargeScreen && (
							<HamburgerButton
								className="header__icon__burger"
								ref={triggerRef}
								onClick={() => setIsMenuOpen(!isMenuOpen)}
								active={isMenuOpen}
							/>
						)}
					</div>
				</div>
			</header>
			{/*** Account popup ***/}
			{isPopupAccountOpen && <PopupAccount />}
		</>
	)
}

export default Header
