import { FC } from 'react';

interface ICalendarIcon {
  readonly className?: string;
}

export const CalendarIcon: FC<ICalendarIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.24998 2.81256V0.562505C2.24998 0.251556 2.50154 0 2.81256 0C3.12344 0 3.37499 0.251556 3.37499 0.562505V2.81256C3.37499 3.12343 3.12344 3.37499 2.81256 3.37499C2.50154 3.37503 2.24998 3.12343 2.24998 2.81256ZM11.8126 3.37503C12.1234 3.37503 12.3751 3.12347 12.3751 2.8126V0.562505C12.3751 0.251556 12.1234 0 11.8126 0C11.5016 0 11.2499 0.251556 11.2499 0.562505V2.81256C11.2499 3.12343 11.5016 3.37503 11.8126 3.37503ZM18 13.4999C18 15.9853 15.9851 18 13.5 18C11.015 18 9 15.9853 9 13.4999C9 11.0149 11.0149 9 13.5 9C15.9851 9 18 11.0149 18 13.4999ZM16.8751 13.4999C16.8751 11.6391 15.3612 10.1249 13.5 10.1249C11.639 10.1249 10.125 11.639 10.125 13.4999C10.125 15.3611 11.639 16.875 13.5 16.875C15.3612 16.875 16.8751 15.3611 16.8751 13.4999ZM4.50004 6.75002H2.24998V9H4.50004V6.75002ZM2.24998 12.375H4.50004V10.1249H2.24998V12.375ZM5.62497 9H7.87507V6.75002H5.62497V9ZM5.62497 12.375H7.87507V10.1249H5.62497V12.375ZM1.12505 13.388V5.62497H13.5V7.87503H14.625V3.48714C14.625 2.80377 14.08 2.24998 13.4067 2.24998H12.9375V2.8126C12.9375 3.43216 12.4331 3.93757 11.8126 3.93757C11.192 3.93757 10.6876 3.43216 10.6876 2.8126V2.24998H3.93746V2.8126C3.93746 3.43216 3.4332 3.93757 2.8126 3.93757C2.19185 3.93757 1.68755 3.43216 1.68755 2.8126V2.24998H1.21894C0.545551 2.24998 0 2.80377 0 3.48714V13.3881C0 14.0692 0.545551 14.6251 1.21894 14.6251H7.87507V13.4999H1.21894C1.16797 13.4999 1.12505 13.4472 1.12505 13.388ZM11.2499 9V6.75002H9.00004V9H11.2499ZM15.1875 13.4999H13.5V11.8123C13.5 11.5016 13.2484 11.25 12.9375 11.25C12.6265 11.25 12.3751 11.5016 12.3751 11.8123V14.0626C12.3751 14.3734 12.6265 14.6251 12.9375 14.6251H15.1875C15.4985 14.6251 15.75 14.3734 15.75 14.0626C15.75 13.7516 15.4985 13.4999 15.1875 13.4999Z"
        fill="white"
      />
    </svg>
  );
};
