import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getUserInfo } from 'shared/api/account'
// Context
import {
	STORE_ACCESS_TOKEN_NAME,
	STORE_REFRESH_TOKEN_NAME,
	STORE_USER_DATA_NAME,
} from 'shared/constants'
import { createSession } from 'shared/lib/auth/sessions'

// Files
import { api } from '../constants/api'

const ConfirmTokenPage = () => {
	const { token } = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		if (!localStorage.getItem(STORE_ACCESS_TOKEN_NAME)) {
			api
				.post(`confirmation-email/${token}/`, {})
				.then(async ({ data }) => {
					createSession(data)
					const user = await getUserInfo()
					localStorage.setItem(STORE_USER_DATA_NAME, JSON.stringify(user))

					setTimeout(() => {
						navigate('/')
					}, 3000)
				})
				.catch(({ response }) => {
					if (response.status === 404) {
						navigate('/404/')
					}
				})
		}
	}, [])

	return (
		<div className="container" style={{ minHeight: 'calc(100vh - 523px)' }}>
			<h1 className="page-title page-title--confirm-token">
				Адрес электронной почты успешно подтвержден
			</h1>
		</div>
	)
}

export default ConfirmTokenPage
