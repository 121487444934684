export const sortPlayers = (playerId: number, players: number[]) => {
	const result = []
	for (let i = players.length - 1, j = 0; i >= 0; i--, j++) {
		if (players[i] === playerId) {
			const temp = result[0] as number
			if (!temp) {
				result[0] = players[i]
			} else {
				result[0] = players[i]
				result[j] = temp
			}
		} else {
			result[j] = players[i]
		}
	}
	return result
}
